import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';

// var data = JSON.stringify({
  
//     "start_date": "2022-05-25",
//     "end_date": "2022-06-25",
//     "filter_category": 2,
//     "patient": "Anandan"
  
  
// });

// var config = {
//   method: 'post',
//   url: 'https://dev-pharmacy.eoraa.com/api/prescription_reservation/search/',
//   headers: { 
//     'Authorization': 'token ea672d2ad5e7fc4997f16c45538541b17392f683', 
    
//     'Content-Type': 'application/json'
//   },
//   data : data
// };

// axios(config)
// .then(function (response) {
//   console.log(response);
// })
// .catch(function (error) {
//   console.log(error);
// });


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
