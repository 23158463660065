/* eslint-disable no-mixed-operators */
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { reservationResObj } from "../../apis/reservation";
import {
  FormRadio,
  FormDateTimeInput,
  Message,
  MainButton,
  FormInput,
} from "../components/projects";
import {
  getDateFromValue,
  getAgeFromValue,
  getJpnDateFromValue,
} from "../../utils/hooks";
import { isEntered } from "../../utils/validation";
import axios from "axios";
import { templateResObj } from "../../apis/template";
import { getFromLocalStorage } from "../../helpers/storage";
import { prefectures } from "../../utils/data/prefectures";

// ダミーデータ
const item = {
  prescriptionDate: "2021/10/02",
  limit: "2022/10/02",
  number: "XXXXXXX",
  userName: "XXXXXXX",
  birthday: "1975/05/10",
  requestDate: "2021/10/02",
  requestTime: "13:00",
  remort: true,
  how: "郵送",
  remortDate: "2021/10/02",
  remortTime: "13:00",
  teach: false,
};

const user = {
  userId: 1,
  gender: "女",
  drugHospital: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
  contact: "03-123-5678",
  drugConctor: "XXXXXXXXXXXXXXXXXXXXXXXX",
  detail: [
    "お薬の名前",
    "お薬の形（錠剤、カプセル剤など）",
    "お薬の量　1回あたりに飲む量",
    "1日に飲む回数",
    "飲むタイミング",
  ],
  change: true,
  time: 3,
  postNumber: "123-4567",
  address: "東京都 大田区 南久が原 1−2−3",
  phoneNumber: "080-1234-5678",
  email: "XXXXXXX@XXX.com",
  allergy:
    "アレルギー情報。アレルギー情報。アレルギー情報。アレルギー情報。アレルギー情報。アレルギー情報。",
  drugAllergy:
    "アレルギー薬剤情報。アレルギー薬剤情報。アレルギー薬剤情報。アレルギー薬剤情報。",
  insuranceLimit: "2022/12/12",
  insuranceDelivery: "2019/03/31",
  insuranceCode: "12345678 番号 1",
  insuranceGet: "2017/03/31",
  householdName: "山田 太郎",
  insuranceCompany: "株式会社オルトロス",
  insuranceNumber: "12345678",
  issurName: "大和市",
  insurer: "全国健康保険協会 東京支部",
  location: "東京都中野区中野4−10−2",
  disease:
    "基礎疾患内容の記載。基礎疾患内容の記載。基礎疾患内容の記載。基礎疾患内容の記載。基礎疾患内容の記載。",
  hospital: "医療機関名。医療機関名。医療機関名。医療機関名。",
  store: "調剤薬局名。調剤薬局名。調剤薬局名。調剤薬局名。",
  pharmacistName: "薬剤師氏名。薬剤師氏名。薬剤師氏名。薬剤師氏名。",
};

// 新着メッセージ
const newMessage = {
  date: "2021/12/22",
  time: "14:55",
  text: "患者からの予約申請メッセージ。患者からの予約申請メッセージ。患者からの予約申請メッセージ。患者からの予約申請メッセージ。患者からの予約申請メッセージ。患者からの予約申請メッセージ。患者からの予約",
  isUser: true,
};

// メッセージ履歴
const messages = [
  {
    date: "2021/12/22",
    time: "14:55",
    text: "患者からの予約申請メッセージ。患者からの予約申請メッセージ。患者からの予約申請メッセージ。患者からの予約申請メッセージ。患者からの予約申請メッセージ。患者からの予約申請メッセージ。患者からの予約",
    isUser: true,
  },
  {
    date: "2021/12/22",
    time: "14:55",
    text: "薬局・薬店からの予約申請返信メッセージ。薬局・薬店からの予約申請返信メッセージ。薬局・薬店からの予約申請返信メッセージ。薬局・薬店からの予約申請返信メッセージ。",
    isUser: false,
  },
];

export default function Reserve() {
  const { id } = useParams();

  const [reservationInfo, setReservationInfo] = useState({
    prescription: [
      {
        Prescription_date: "",
        time: null,
        isRed: false,
        insurer_number: "",
        name: "",
        birthday: "",
        gender: "",
        medical_institution_name: "",
        contact: "",
        prescribing_physician: "",
        prescription_details: "",
        change_to_generic_drugs: "",
        number_of_split_dispensing: 6,
        prescription_image: "",
        patient: "",
      },
    ],
    scheduled_time: "",
    guidance_scheduled_time: "",
    patient: {
      address: {
        address: "",
        city: "",
        prefecture: "",
        telephone: "",
        patient: null,
      },

      patient_insurance: [
        {
          expiration_date: "2020-02-03",
          date_of_issuance: "",
          symbol: "",
          date_of_qualification: "",
          name_of_head_of_household: "",
          office_name: "",
          insurance_number: "",
          recipient_name: "",
          insurer_name: "",
          insurer_location: "",
          underlying_disease: "",
          family_medical_institution: "",
          family_dispensing_pharmacy: "",
          pharmacist_in_charge: "",
        },
      ],
    },
    reservation_messages: [
      {
        message: "",
        created_at: "",
      },
    ],
  });
  const [reservationPresDetails, setReservationPresDetails] = useState([]);
  const [templateInfo, setTemplateInfo] = useState([]);
  const [availableTemplate, setAvailableTemplate] = useState([]);
  const [reserve_message1, setReserveMessage1] = useState([]);
  const [reserve_message2, setReserveMessage2] = useState([]);
  const [pharmaMessage, setPharmaMessage] = useState([]);
  const [send, setSend] = useState(false);
  const [expire, setExpire] = useState("");
  const [diseases, setDisease] = useState("");
  const [hospital, setHospital] = useState("");
  const [store, setStore] = useState("");
  const [pharmacist, setPharamcist] = useState("");
  const [reserveDate, setReserveDate] = useState({ date: "", time: "" });
  const [remoteDate, setRemoteDate] = useState({ date: "", time: "" });
  const [formRadioInput, setFormRadioInput] = useState("承諾しない");
  const [dateCheck, setDateCheck] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (getFromLocalStorage("token") === null) {
      navigate("/");
    } else {
      console.log("token present");
    }
    var response1,
      response2,
      res_mes1 = [],
      res_mes2 = [];
    response1 = reservationResObj.reservationDetailsWithId(id);
    response1.then((res) => {
      console.log(res.data);
      var temp1 = "",
        temp2 = "",
        temp3 = "",
        temp4 = "";
      for (var i = 0; i < res.data.patient.patient_insurance.length; i++) {
        temp1 =
          res.data.patient.patient_insurance[i].underlying_disease != null &&
          res.data.patient.patient_insurance[i].underlying_disease != ""
            ? temp1 +
              res.data.patient.patient_insurance[i].underlying_disease +
              ","
            : temp1 + "";
        temp2 =
          res.data.patient.patient_insurance[i].family_dispensing_pharmacy !=
            null &&
          res.data.patient.patient_insurance[i].family_dispensing_pharmacy != ""
            ? temp2 +
              res.data.patient.patient_insurance[i].family_dispensing_pharmacy +
              ","
            : temp2 + "";
        temp3 =
          res.data.patient.patient_insurance[i].family_medical_institution !=
            null &&
          res.data.patient.patient_insurance[i].family_medical_institution != ""
            ? temp3 +
              res.data.patient.patient_insurance[i].family_medical_institution +
              ","
            : temp3 + "";
        temp4 =
          res.data.patient.patient_insurance[i].pharmacist_in_charge != null &&
          res.data.patient.patient_insurance[i].pharmacist_in_charge != ""
            ? temp4 +
              res.data.patient.patient_insurance[i].pharmacist_in_charge +
              ","
            : temp4 + "";
      }

      console.log(temp1, temp2, temp3, temp4);
      setDisease(temp1);
      setHospital(temp2);
      setStore(temp3);
      setPharmaMessage(temp4);

      setReservationInfo(res.data);

      if (res.data.prescription.length != 0)
        setReservationPresDetails([
          res.data.prescription[0].prescription_details,
        ]);
      else setReservationPresDetails([""]);

      for (var i = 0; i < res.data.reservation_messages.length; i++) {
        if (i > 2) break;
        res_mes1.push(res.data.reservation_messages[i]);
      }

      for (var i = 0; i < res.data.reservation_messages.length; i++) {
        if (i > 2) {
          res_mes2.push(res.data.reservation_messages[i]);
        }
      }

      setReserveMessage1(res_mes1);
      setReserveMessage2(res_mes2);

      var presDate;
      if (res.data.prescription.length != 0) {
        presDate = res.data.prescription[0].prescription_date;
        presDate = presDate.split("-");
        var date = Number(presDate[2]);
        var month = Number(presDate[1]);
        var year = Number(presDate[0]);
        presDate[2] = String(date + 4);

        if (
          (presDate[2] > 30 &&
            month == 2 &&
            year % 4 != 0 &&
            year % 400 != 0 &&
            year % 100 != 0) ||
          (presDate[2] > 30 && month == 6) ||
          (presDate[2] > 30 && month == 9) ||
          (presDate[2] > 30 && month == 11)
        ) {
          presDate[1] = month + 1;
          presDate[2] = date % 10;
        } else if (
          (presDate[2] > 31 && month != 2) ||
          (presDate[2] > 31 && month != 6) ||
          (presDate[2] > 31 && month != 9) ||
          (presDate[2] > 31 && month != 11)
        ) {
          presDate[1] = month + 1;
          presDate[2] = date % 10;
        } else if (
          presDate[2] > 28 &&
          month == 2 &&
          year % 4 == 0 &&
          year % 400 == 0 &&
          year % 100 == 0
        ) {
          presDate[1] = month + 1;
          presDate[2] = date % 10;
        } else if (presDate[2] > 31 && month == 12) {
          presDate[0] = year + 1;
          presDate[1] = 1;
          presDate[2] = date % 10;
        }

        presDate = presDate.join("/");
        setExpire(presDate);
      }
    });

    response2 = templateResObj.template();
    response2.then((res) => {
      console.log(res);
      const resArr1 = [],
        resArr2 = [];
      resArr1.push({ text: "Create a template", id: 0 });
      for (var i = 0; i < res.data.length; i++) {
        resArr1.push({ text: res.data[i].title, id: res.data[i].id });
        resArr2.push({ text: res.data[i].title, id: res.data[i].id });
      }
      // console.log(resArr1)

      setTemplateInfo(resArr1);
      setAvailableTemplate(resArr2);
    });
  }, []);

  var reservation_date = "",
    reservation_time = "";
  var guidance_date = "",
    guidance_time = "";

  if (reservationInfo.reservation_scheduled_time != null) {
    reservation_time = reservationInfo.reservation_scheduled_time.substring(
      11,
      16
    );
    reservation_date = reservationInfo.reservation_scheduled_time.substring(
      0,
      10
    );
  }

  if (reservationInfo.guidance_scheduled_time != null) {
    guidance_time = reservationInfo.guidance_scheduled_time.substring(11, 16);
    guidance_date = reservationInfo.guidance_scheduled_time.substring(0, 10);
  }

  // 予約申請日
  const requestRadio = [
    {
      label: "承諾する",
      value: "承諾する",
      initial: false,
    },
    {
      label: "承諾しない",
      value: "承諾しない",
      initial: true,
    },
  ];

  // 予約
  const [request, setRequest] = useState("承諾しない");
  const handleChangeType = (e) => {
    setRequest(e);
  };

  // リモート予約
  const [remort, setRemort] = useState("承諾しない");
  const handleRemortChangeType = (e) => {
    // const reserVal=document.getElementsByName("reserveValue");
    // const remoteVal=document.getElementsByName("remoteValue");
    // console.log(reserVal);
    // console.log(remoteVal);

    setRemort(e);
  };

  // テンプレート
  const template = [
    "テンプレート1",
    "テンプレート2",
    "テンプレート3",
    "テンプレート4",
    "テンプレート5",
  ];

  // テンプレート選択
  const [textValues, setTextValues] = useState({
    textValue: "",
    templateValue: "",
    templateTextValue: "",
    savedTemplateValue: "",
  });
  const handleSelectChangeTemplate = () => {
    var response;
    const temp_id = document.getElementById("send-message").value;
    response = templateResObj.getTemplateInfo(temp_id);
    response.then((res) => {
      document.getElementById("message").value = res.data.body;
    });
  };

  const handleSelectChange = (e) => {
    const template_id = document.getElementById("template-option").value;
    if (template_id > 0) {
      var response;
      response = templateResObj.getTemplateInfo(template_id);
      response.then((res) => {
        document.getElementById("template-title").value = res.title;
        document.getElementById("template-body").value = res.body;
      });
    } else {
      document.getElementById("template-title").value = "";
      document.getElementById("template-body").value = "";
    }

    if (openForm) {
      setTextValues({
        ...textValues,
        templateTextValue: e.target.value,
        savedTemplateValue: e.target.value,
      });
    } else {
      setTextValues({
        ...textValues,
        textValue: e.target.value,
        templateValue: e.target.value,
      });
    }
  };

  const handleTextChange = (e) => {
    if (openForm) {
      setTextValues({
        ...textValues,
        templateTextValue: e.target.value,
        SavedTemplateValue: e.target.value,
      });
    } else {
      setTextValues({
        ...textValues,
        textValue: e.target.value,
        templateValue: e.target.value,
      });
    }
  };

  // テンプレート作成フォーム
  const [openForm, setOpenForm] = useState(false);
  const handleClickCreate = () => {
    setOpenForm((prevState) => !prevState);
    setErrorMessages({
      ...errorMessages,
      templateTitle: "",
    });
  };

  // バリデーション
  const [errorMessages, setErrorMessages] = useState({});
  const isValidate = (values) => {
    let messages = {};

    if (openForm) {
      messages.templateTitle = isEntered(
        "テンプレートタイトル",
        values.templateTitle,
        false
      );
    } else if (item.teach) {
      messages.reservationDateTime =
        values.reserveValue === "承諾しない"
          ? isEntered("予約申請日時", values.reservationDate, false) ||
            isEntered("予約申請日時", values.reservationTime, false)
          : "";
    } else {
      messages.reservationDateTime =
        values.reserveValue === "承諾しない"
          ? isEntered("予約申請日時", values.reservationDate, false) ||
            isEntered("予約申請日時", values.reservationTime, false)
          : "";
      messages.teachDateTime =
        values.teachValue === "承諾しない"
          ? isEntered("予約申請日時", values.teachDate, false) ||
            isEntered("予約申請日時", values.teachTime, false)
          : "";
    }

    setErrorMessages({ ...messages });
    return Object.values(messages).every((message) => message === "");
  };

  // メッセージ送信処理

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const values = Object.fromEntries(data.entries());

    if (isValidate(values)) {
      console.log(values);
      navigate(window.location.pathname);
    }
  };

  // テンプレート作成処理
  const handleCreateTemplate = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const values = Object.fromEntries(data.entries());

    if (isValidate(values)) {
      const templateVal = document.getElementById("template-option").value;
      console.log(document.getElementById("template-option").value);
      var response;

      if (templateVal == 0) {
        response = templateResObj.createTemplate(values, id);
        response.then((res) => {
          setTimeout(() => {
            window.location.href = `/reserve/${id}`;
          }, 2000);
        });
      } else {
        response = templateResObj.editTemplate(values, templateVal);
        response.then((res) => {
          setTimeout(() => {
            window.location.href = `/reserve/${id}`;
          }, 2000);
        });
      }
      setOpenForm((prevState) => !prevState);
    }
  };

  // メッセージ追加取得
  const [addition, setAddition] = useState(false);
  const fetchMoreMessage = () => {
    setAddition((prevState) => !prevState);
  };

  const dateValidate = () => {
    const reservaVal = document.getElementsByName("reserveValue");
    const teachVal = document.getElementsByName("teachValue");

    console.log(teachVal.length);
    var flag = 0;

    console.log(remoteDate);

    if (
      reserveDate.date == "" &&
      reserveDate.time == "" &&
      reservaVal[1].checked
    ) {
      setDateCheck("日時は必須項目です");
      window.scroll({
        top: 1000,
        behavior: "smooth",
      });
      if (
        reserveDate.date == "" &&
        reserveDate.time == "" &&
        reservaVal[1].checked
      )
        document.getElementById("error-1").style.display = "block";
      else document.getElementById("error-1").style.display = "none";
      return false;
    } else {
      setDateCheck("");
      document.getElementById("error-1").style.display = "none";
    }

    if (teachVal.length != 0) {
      if (
        reservationInfo.medication_guidence == true &&
        teachVal[1].checked &&
        remoteDate.date == "" &&
        remoteDate.time == ""
      ) {
        setDateCheck("日時は必須項目です");
        window.scroll({
          top: 1000,
          behavior: "smooth",
        });
        if (
          remoteDate.date == "" &&
          remoteDate.time == "" &&
          teachVal[1].checked
        )
          document.getElementById("error-2").style.display = "block";
        else document.getElementById("error-2").style.display = "none";

        return false;
      } else {
        document.getElementById("error-2").style.display = "none";
      }
    }

    return true;
  };

  const messageSend = (e) => {
    e.preventDefault();

    if (dateValidate()) {
      var response;
      const message = document.getElementById("message").value;
      const option_val = document.getElementById("send-message").value;
      const originalReserveDate = reservationInfo.reservation_scheduled_time;
      const originalGuidanceDate = reservationInfo.guidance_scheduled_time;
      const reserveAccepted = reservationInfo.is_accepted;
      const remoteAccepted = reservationInfo.is_remote_accepted;

      const reserveVal = document.getElementsByName("reserveValue");
      const teachVal = document.getElementsByName("teachValue");
      var reserveAcceptByPharma = false;
      var remoteAcceptByPharma = false;

      if (reserveVal[0].checked) reserveAcceptByPharma = true;

      if (teachVal.length != 0) {
        if (teachVal[0].checked) remoteAcceptByPharma = true;
      }

      response = templateResObj.sendMessage(
        message,
        reserveDate,
        remoteDate,
        reserveAccepted,
        remoteAccepted,
        originalReserveDate,
        originalGuidanceDate,
        id,
        option_val,
        reserveAcceptByPharma,
        remoteAcceptByPharma
      );
      response.then((res) => {
        console.log(res.data);
        setPharmaMessage([res.data]);
        setSend(true);

        setTimeout(() => {
          window.location.href = `/reserve/${id}`;
        }, 1000);
      });
    }
  };

  const setDate = (value) => {
    console.log(value);
  };

  const changeDate = (name, value) => {
    if (name == "reservationDate")
      setReserveDate({ ...reserveDate, date: value });
    else if (name == "reservationTime")
      setReserveDate({ ...reserveDate, time: value });
    else if (name == "teachDate") setRemoteDate({ ...remoteDate, date: value });
    else if (name == "teachTime") setRemoteDate({ ...remoteDate, time: value });
  };

  const line = () => {
    window.open("https://line.me/R/nv/chat");
  };

  const getAge = (dob) => {
    const age = getAgeFromValue(dob);
    if (age == 0) return "";

    return age + "歳";
  };

  const validCheck = () => {
    const reservaVal = document.getElementsByName("reserveValue");
    const teachVal = document.getElementsByName("teachValue");
    console.log("heieii");
    if (reservaVal[0].checked) {
      document.getElementById("error-1").style.display = "none";
    }

    if (teachVal.length != 0) {
      if (teachVal[0].checked)
        document.getElementById("error-2").style.display = "none";
    }
  };

  console.log("date" + reservation_date);
  console.log("reserveinfo", reservationInfo);

  const renderUserInfoAddress = (item) => {
    const codePrefecture = item?.prefecture;
    const resultPrefecture = prefectures.find((d) => d.code === codePrefecture);
    if (!item) return;
    return (
      <div>
        {item?.postal_code && <div>〒{item?.postal_code}</div>}
        <div>
          {(resultPrefecture && resultPrefecture.name) || ""} {item?.city || ""}{" "}
          {item?.address || ""}
        </div>
      </div>
    );
  };
  return (
    <>
      {reservationInfo && (
        <>
          <section>
            <form onSubmit={handleSubmit}>
              <h2 className="c-sectionTitle">
                <span>処方受け取り予約</span>
              </h2>
              <dl className="l-table">
                <div>
                  <dt>処方年月日</dt>
                  <dd>
                    {reservationInfo.prescription.length != 0
                      ? getDateFromValue(
                          reservationInfo.prescription[0].Prescription_date
                        )
                      : ""}
                    <span className="l-table__limit">
                      有効期限 &#058; {getDateFromValue(expire)}まで
                    </span>
                  </dd>
                </div>
                <div>
                  <dt>保険者番号</dt>
                  <dd>
                    {reservationInfo.prescription.length != 0
                      ? reservationInfo.prescription[0].insurer_number
                      : ""}
                  </dd>
                </div>
                <div>
                  <dt>お名前</dt>
                  <dd>
                    {reservationInfo.patient
                      ? reservationInfo.patient.name
                      : ""}
                  </dd>
                </div>
                <div>
                  <dt>生年月日</dt>
                  <dd>
                    <>
                      {reservationInfo.patient != null
                        ? getDateFromValue(reservationInfo.patient.dob)
                        : ""}
                      {reservationInfo.patient != null
                        ? getAge(reservationInfo.patient.dob)
                        : ""}
                    </>
                  </dd>
                </div>
                <div>
                  <dt>処方受け取り予約申請年月日</dt>
                  <dd>
                    {getDateFromValue(reservation_date)}
                    <span className="l-table__space">{reservation_time}</span>
                  </dd>
                </div>
              </dl>

              <div className="p-reserve__form">
                <div className="l-formInput">
                  <FormRadio
                    name="reserveValue"
                    title="予約申請日について"
                    options={requestRadio}
                    required={true}
                    method={(value) => {
                      setFormRadioInput(value);
                    }}
                    check={validCheck}
                    initial={1}
                  />
                  {formRadioInput === "承諾しない" ? (
                    <FormDateTimeInput
                      label="処方受け取り予約変更候補日"
                      required={true}
                      errorMessage={
                        errorMessages.reservationDateTime
                          ? errorMessages.reservationDateTime
                          : ""
                      }
                      initialValue=""
                      dateName="reservationDate"
                      timeName="reservationTime"
                      text="予約申請日について"
                      name="reserveDate"
                      method={changeDate}
                    />
                  ) : (
                    ""
                  )}
                </div>

                <p
                  style={{
                    textAlign: "center",
                    color: "#faa04d",
                    marginTop: "20px",
                    display: "none",
                  }}
                  id="error-1"
                >
                  <i
                    class="fas fa-exclamation-triangle"
                    style={{ color: "#faa04d" }}
                  ></i>
                  &nbsp;{dateCheck}
                </p>
              </div>

              <dl className="l-table flex">
                <div className="last">
                  <dt>リモート服薬指導</dt>
                  <dd>
                    {reservationInfo.medication_guidence == true
                      ? "希望する"
                      : "希望しない"}
                  </dd>
                </div>
                <div className="last">
                  <dt>リモート服薬指導時の処方受け取りについて</dt>
                  <dd>
                    {item.how === "郵送"
                      ? "郵送で処方を受け取る"
                      : "予約して受け取る"}
                  </dd>
                </div>
                <div>
                  <dt>指導予約申請日</dt>
                  <dd>
                    {getDateFromValue(guidance_date)}
                    <span className="l-table__space">{guidance_time}</span>
                  </dd>
                </div>
              </dl>

              <>
                {reservationInfo.medication_guidence ? (
                  <>
                    {item.teach ? (
                      <div className="p-reserve__form">
                        <div className="l-formInput">
                          <FormRadio
                            name="remoteValue"
                            title="リモート服薬指導予約申請日について"
                            options={requestRadio}
                            required={true}
                            initial={1}
                            method={setDate}
                          />

                          <div className="l-formInput__form border">
                            <label>
                              服薬指導メモ
                              <span className="l-formInput__any">任意</span>
                            </label>
                            <textarea
                              name="memo"
                              placeholder="服薬指導メモを入力してください"
                              className="c-textarea__border"
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="p-reserve__form">
                        <div className="l-formInput">
                          <FormRadio
                            name="teachValue"
                            title="リモート服薬指導予約申請日について"
                            options={requestRadio}
                            required={true}
                            method={handleChangeType}
                            initial={1}
                            check={validCheck}
                          />
                          {request === "承諾しない" && (
                            <FormDateTimeInput
                              label="処方受け取り予約変更候補日"
                              required={true}
                              errorMessage={
                                errorMessages.teachDateTime
                                  ? errorMessages.teachDateTime
                                  : ""
                              }
                              initialValue=""
                              dateName="teachDate"
                              timeName="teachTime"
                              text="リモート服薬指導予約申請日について"
                              method={changeDate}
                            />
                          )}
                        </div>
                        <p
                          style={{
                            textAlign: "center",
                            color: "#faa04d",
                            marginTop: "20px",
                            display: "none",
                          }}
                          id="error-2"
                        >
                          <i
                            class="fas fa-exclamation-triangle"
                            style={{ color: "#faa04d" }}
                          ></i>
                          &nbsp;{dateCheck}
                        </p>
                      </div>
                    )}
                  </>
                ) : (
                  ""
                )}
              </>
              <div>
                <div className="p-reserve__lineButton">
                  <button
                    onClick={() => {
                      line();
                    }}
                  >
                    LINE通話でリモート服薬指導を始める
                  </button>
                </div>
                <div
                  style={{
                    // marginTop: "-26px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p
                    style={{
                      paddingLeft: "27px",
                      paddingRight: "27px",
                      font: "11px",
                      fontFamily: "Noto Sans JP",
                      fontWeight: "400",
                      paddingBottom: "20px",
                      paddingBottom: "0%",
                      marginBottom: "0%",
                    }}
                  >
                    ※遠隔服薬指導時にはLINEを利用致します。
                  </p>
                  <p
                    style={{
                      paddingLeft: "12px",
                      paddingRight: "4px",
                      font: "11px",
                      fontFamily: "Noto Sans JP",
                      fontWeight: "400",
                    }}
                  >
                    薬局とLINEIDの交換を事前にメッセージでお済ませください。
                  </p>
                </div>
              </div>
              <article className="l-message">
                <h3>予約メッセージ</h3>
                <ul className="new">
                  <Message
                    item={reservationInfo.reservation_messages[0]}
                    isSeen={reservationInfo.is_seen_pharmacy}
                  />
                </ul>
              </article>

              {/* <>
          <article className="l-message">
            
           
          {send && pharmaMessage.map((item)=>{
            
          return (
         
               <Message 
                item={item}
                
                key={item.id}
              />
             
           
          )
          
        })}
        
          </article> 
          </> */}

              <div className="p-reserve__form">
                <div className="l-formInput">
                  <div className="c-selectBox__border p-reserve__form__select">
                    <div className="c-selectBox__container">
                      <select
                        id="send-message"
                        onChange={handleSelectChangeTemplate}
                      >
                        <option hidden value="">
                          返信テンプレートを選択する
                        </option>
                        {availableTemplate &&
                          availableTemplate.map((item, i) => {
                            return (
                              <option value={item.id} key={item.id}>
                                {item.text}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="l-formInput__form border">
                    <label>
                      返信メッセージ
                      <span className="l-formInput__any">任意</span>
                    </label>
                    <textarea
                      name="message"
                      placeholder="予約申請メッセージの詳細を入力してください"
                      className="c-textarea__border"
                      // value={textValues.textValue}
                      onChange={handleTextChange}
                      id="message"
                    />
                  </div>
                  <MainButton
                    text="この内容で返信する"
                    color="blue min"
                    onClick={messageSend}
                  />
                </div>
              </div>
            </form>
            <div className="c-linkText bold">
              <button onClick={handleClickCreate}>
                返信テンプレートを作成・編集する
              </button>
            </div>

            <article className="l-message">
              <h3>これまでのメッセージ</h3>
              <ul>
                {reserve_message1 &&
                  reserve_message1.map((item, i) => {
                    return <Message key={i} item={item} />;
                  })}
              </ul>
              {!addition && (
                <MainButton
                  text="メッセージをもっと見る"
                  color="message"
                  onClick={fetchMoreMessage}
                />
              )}

              {addition && (
                <ul>
                  {reserve_message2.length != 0 ? (
                    <>
                      {reserve_message2 &&
                        reserve_message2.map((item, i) => {
                          return <Message key={i} item={item} />;
                        })}
                    </>
                  ) : (
                    ""
                  )}
                </ul>
              )}
            </article>

            <article className="p-reserve__attention">
              <p>
                今回の処方箋にはアレルギーとなる薬剤が含まれている可能性があります。処方時にはご注意ください。
              </p>
            </article>

            <dl className="l-table">
              <div>
                <dt>性別</dt>
                <dd>{reservationInfo.patient.gender}</dd>
              </div>
              <div>
                <dt>医療機関名</dt>
                <dd>
                  {reservationInfo.prescription.length != 0
                    ? reservationInfo.prescription[0].medical_institution_name
                    : ""}
                </dd>
              </div>
              <div>
                <dt>連絡先</dt>
                <dd>
                  {reservationInfo.patient.phone_no != null
                    ? reservationInfo.patient.phone_no
                    : ""}
                </dd>
              </div>
              <div>
                <dt>処方担当医師</dt>
                <dd>
                  {reservationInfo.prescription.length != 0
                    ? reservationInfo.prescription[0].prescribing_physician
                    : ""}
                </dd>
              </div>
              <div>
                <dt>処方詳細</dt>

                {reservationPresDetails &&
                  reservationPresDetails.map((item, i) => {
                    return <dd key={i}>{item}</dd>;
                  })}
              </div>
              <div>
                <dt>ジェネリック医薬品への変更</dt>
                <dd>
                  {reservationInfo.prescription.length != 0
                    ? reservationInfo.prescription[0].change_to_generic_drugs
                      ? "変更可"
                      : "変更不可"
                    : ""}
                </dd>
              </div>
              <div className="last">
                <dt>分割調剤の回数</dt>
                <dd>
                  {reservationInfo.prescription.length != 0
                    ? reservationInfo.prescription[0].number_of_split_dispensing
                    : ""}
                  回
                </dd>
              </div>
              <div>
                <dt>処方箋画像</dt>
                <dd>
                  {/* <div className="l-table__img">
              
              </div> */}
                  <img
                    src={
                      reservationInfo.prescription.length != 0
                        ? reservationInfo.prescription[0].prescription_image
                        : ""
                    }
                    alt="prescription-image"
                    style={{ width: "70%", height: "40vh" }}
                  />
                </dd>
              </div>
            </dl>
          </section>

          <section className="p-reserve__userInfo">
            <h2 className="c-sectionTitle userInfo">
              <span>患者情報</span>
            </h2>
            <dl className="l-table">
              <div>
                <dt>住所</dt>
                <dd>
                    {renderUserInfoAddress(reservationInfo?.patient?.address)}
                </dd>
              </div>
              <div>
                <dt>電話</dt>
                <dd>
                  {reservationInfo.patient.phone_no != null
                    ? reservationInfo.patient.phone_no
                    : ""}
                </dd>
              </div>
              <div>
                <dt>メールアドレス</dt>
                <dd>
                  {reservationInfo.patient.email != null
                    ? reservationInfo.patient.email
                    : ""}
                </dd>
              </div>
              <div>
                <dt>アレルギー情報</dt>
                <dd>
                  {reservationInfo.patient.allergy_info != null
                    ? reservationInfo.patient.allergy_info
                    : ""}
                </dd>
              </div>
              <div>
                <dt>アレルギー反応薬剤</dt>
                <dd>
                  {reservationInfo.patient.allergy_reactive != null
                    ? reservationInfo.patient.allergy_reactive
                    : ""}
                </dd>
              </div>
              <div>
                <dt>保険証情報</dt>
                <dd className="insurance">
                  <span>
                    有効期限 &#058;{" "}
                    {reservationInfo.patient.patient_insurance.length != 0
                      ? getJpnDateFromValue(
                          reservationInfo.patient.patient_insurance[0]
                            .expiration_date
                        )
                      : ""}
                  </span>
                  <span>
                    交付年月日 &#058;{" "}
                    {reservationInfo.patient.patient_insurance.length != 0
                      ? getJpnDateFromValue(
                          reservationInfo.patient.patient_insurance[0]
                            .date_of_issuance
                        )
                      : ""}
                    交付
                  </span>
                  <span>
                    記号 &#058;{" "}
                    {reservationInfo.patient.patient_insurance.length != 0
                      ? reservationInfo.patient.patient_insurance[0].symbol
                      : ""}
                  </span>
                  <span>
                    資格取得年月日 &#058;{" "}
                    {reservationInfo.patient.patient_insurance.length != 0
                      ? getJpnDateFromValue(
                          reservationInfo.patient.patient_insurance[0]
                            .date_of_qualification
                        )
                      : ""}
                    交付
                  </span>
                  <span>
                    世帯主 &#058;{" "}
                    {reservationInfo.patient.patient_insurance.length != 0
                      ? reservationInfo.patient.patient_insurance[0]
                          .name_of_head_of_household
                      : ""}
                  </span>
                  <span>
                    事業所名称 &#058;{" "}
                    {reservationInfo.patient.patient_insurance.length != 0
                      ? reservationInfo.patient.patient_insurance[0].office_name
                      : ""}
                  </span>
                  <span>
                    保険者番号 &#058;{" "}
                    {reservationInfo.patient.patient_insurance.length != 0
                      ? reservationInfo.patient.patient_insurance[0]
                          .insurance_number
                      : ""}
                  </span>
                  <span>
                    交付者名 &#058;{" "}
                    {reservationInfo.patient.patient_insurance.length != 0
                      ? reservationInfo.patient.patient_insurance[0]
                          .recipient_name
                      : ""}
                  </span>
                  <span>
                    保険者名称 &#058;{" "}
                    {reservationInfo.patient.patient_insurance.length != 0
                      ? reservationInfo.patient.patient_insurance[0]
                          .insurer_name
                      : ""}
                  </span>
                  <span>
                    保険者所在地 &#058;{" "}
                    {reservationInfo.patient.patient_insurance.length != 0
                      ? reservationInfo.patient.patient_insurance[0]
                          .insurer_location
                      : ""}
                  </span>
                </dd>
              </div>
              <div>
                <dt>過去の処方履歴</dt>
                <dd>
                  <Link
                    to={`/patient/${
                      reservationInfo.prescription.length != 0
                        ? reservationInfo.prescription[0].patient
                        : "1"
                    }/history`}
                  >
                    この患者の処方箋履歴をみる
                  </Link>
                </dd>
              </div>
              <div>
                <dt>基礎疾患</dt>
                <dd>{diseases != "" ? diseases : ""}</dd>
              </div>
              <div>
                <dt>かかりつけ医療機関</dt>
                <dd>{hospital != "" ? hospital : ""}</dd>
              </div>
              <div>
                <dt>かかりつけ調剤薬局</dt>
                <dd>{store != "" ? store : ""}</dd>
              </div>
              <div>
                <dt>担当薬剤師</dt>
                <dd>{pharmacist != "" ? pharmacist : ""}</dd>
              </div>
            </dl>
            <div className="c-linkText bold">
              <button onClick={() => navigate(-1)}>
                処方受け取り予約一覧に戻る
              </button>
            </div>
          </section>

          <section className={openForm ? "l-confirm open" : "l-confirm"}>
            <button
              className="l-confirm__closeBtn"
              onClick={handleClickCreate}
            />
            <h2>返信テンプレート作成・編集</h2>
            <div className="l-confirm__table">
              <form className="l-formInput" onSubmit={handleCreateTemplate}>
                <div className="c-selectBox__border p-reserve__form__select">
                  <label>設定済みテンプレート</label>
                  <div className="c-selectBox__container">
                    <select
                      id="template-option"
                      onChange={handleSelectChange}
                      value={textValues.savedTemplateValue}
                    >
                      <option hidden value="">
                        返信テンプレートを選択する
                      </option>
                      {templateInfo &&
                        templateInfo.map((item, i) => {
                          return (
                            <option value={item.id} key={item.id}>
                              {item.text}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <FormInput
                  name="templateTitle"
                  NULL
                  label="テンプレートタイトル"
                  placeholder="テンプレートタイトルを入力してください"
                  required="true"
                  type="text"
                  errorMessage={
                    errorMessages.templateTitle
                      ? errorMessages.templateTitle
                      : ""
                  }
                  border={true}
                />
                <div className="l-formInput__form border">
                  <label>テンプレート本文</label>
                  <textarea
                    name="templateText"
                    placeholder="テンプレート本文を入力してください"
                    className="c-textarea__border"
                    // value={textValues.templateTextValue}
                    id="template-body"
                    onChange={handleTextChange}
                  />
                </div>
                <MainButton
                  text="この内容でテンプレートを作成・編集する"
                  color="blue min"
                />
              </form>
            </div>
          </section>
        </>
      )}
    </>
  );
}

//new comment //
