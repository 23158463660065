import { useState, memo, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { FormInput, MainButton, LinkText } from "../components/projects";
import { isEmail, isPassword } from "../../utils/validation";
import logInAPI from "../../apis/login";
import { setLocalStorage } from "../../helpers/storage";
import { noticeResObj } from "../../apis/noticeAPI";
import { NewsList } from "../components/projects";
import signUp from "../../apis/signup";

const SignIn = memo(() => {
  const [noticeData, setNoticeData] = useState([]);

  // 入力バリデーション
  const [errorMessages, setErrorMessages] = useState({});
  const isValidate = (values) => {
    let messages = {};

    messages.email = isEmail(values.email);
    messages.password = isPassword(values.password, "ログインパスワード");

    setErrorMessages({ ...messages });
    return Object.values(messages).every((message) => message === "");
  };

  // ログイン処理
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const values = Object.fromEntries(data.entries());
    console.log(values);
    document.getElementById("error-message").innerHTML = "";

    if (isValidate(values)) {
      try {
        const response = await signUp.signUpAPI(values);
        console.log(response);
        setLocalStorage({ token: response.data.user_token });
      } catch (e) {
        console.log("error", e);
      }
      console.log("ok");
    }
  };

  return (
    <>
      <section className="p-signIn">
        <h2>薬局・薬店様管理画面ログイン</h2>
        <form className="l-formInput" onSubmit={handleSubmit}>
          <FormInput
            name="email"
            label="ご登録ID（メールアドレス）"
            placeholder="ログインID（メールアドレス）を入力してください"
            required="true"
            type="email"
            errorMessage={errorMessages.email ? errorMessages.email : ""}
            autocomplete="email"
          />
          <FormInput
            name="password"
            label="ご登録パスワード"
            placeholder="ログインパスワードを入力してください"
            required="true"
            type="password"
            errorMessage={errorMessages.password ? errorMessages.password : ""}
            autocomplete="off"
          />
          <p id="error-message" style={{ color: "red" }}></p>
          <MainButton text="ログインする" color="blue" />
        </form>

        {/* <LinkText url="/password" text="ログインID・パスワードを忘れた方へ" /> */}
      </section>
      <br />
      <br />
      <ul>
        {noticeData &&
          noticeData.map((item, i) => {
            return <NewsList key={i} item={item} />;
          })}
      </ul>
    </>
  );
});

export default SignIn;
