const regex = /([0-9].*[a-zA-Z]|[a-zA-Z].*[0-9])/;

export const isPassword = (value, name) => {
  if (value.trim().includes(" ")) {
    return "remove the spaces";
  } else if (!value) {
    return `${name}は必須入力項目です。`;
  } else if (!regex.test(value)) {
    return `${name}は半角英数でご入力ください。`;
  } else {
    return "";
  }
};
