import { memo } from "react";
import { Link } from "react-router-dom";

const HistoryList = memo((props) => {

  const { Prescription_date, time, medical_institution_name, prescribing_physician,id} = props.item;
  const pid=props.patientId;

  

  return (
    <li className="c-messageList history">
      <Link  state={{reserveId: id}} to={`${window.location.pathname}/${pid}`}>
        <p className="c-messageList__time">
          処方日時 &#058;&nbsp; <span>{Prescription_date}</span><span>{time}</span>
        </p>
        <p className="c-messageList__medical">
          <span className="c-messageList__hospital">{medical_institution_name}</span>
          <span className="c-messageList__doctor">{prescribing_physician}</span>
        </p>
        <p className="c-messageList__medicine">
          {""}
        </p>
      </Link>
    </li>
  )
})

export default HistoryList