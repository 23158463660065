import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { patientDetailsRes } from "../../apis/patientDetails";

import {
  SearchPatientForm,
  PatientList as List,
  ActionButton,
} from "../components/projects";
import { CsvResObj } from "../../apis/import_export_csv";
import axios from "axios";
import { getFromLocalStorage } from "../../helpers/storage";
import { Pagination } from "antd";
import "antd/dist/antd.css";

// ダミーデータ
const users = {
  all: "xxxx",
  service: "xxxx",
  not: "xxxx",
  use: "xxxx",
};

const items = [
  {
    date: "2021/12/21",
    time: "13:00",
    name: "山田 花子",
    birthday: "1975/10/02",
    number: "12345678",
    red: true,
    id: 1,
  },
  {
    date: "2021/12/21",
    time: "13:00",
    name: "山田 花子",
    birthday: "1975/10/02",
    number: "12345678",
    red: false,
    id: 2,
  },
  {
    date: "2021/12/21",
    time: "13:00",
    name: "山田 花子",
    birthday: "1975/10/02",
    number: "12345678",
    red: false,
    id: 3,
  },
  {
    date: "2021/12/21",
    time: "13:00",
    name: "山田 花子",
    birthday: "1975/10/02",
    number: "12345678",
    red: true,
    id: 4,
  },
  {
    date: "2021/12/21",
    time: "13:00",
    name: "山田 花子",
    birthday: "1975/10/02",
    number: "12345678",
    red: false,
    id: 5,
  },
  {
    date: "2021/12/21",
    time: "13:00",
    name: "山田 花子",
    birthday: "1975/10/02",
    number: "12345678",
    red: false,
    id: 6,
  },
  {
    date: "2021/12/21",
    time: "13:00",
    name: "山田 花子",
    birthday: "1975/10/02",
    number: "12345678",
    red: true,
    id: 7,
  },
  {
    date: "2021/12/21",
    time: "13:00",
    name: "山田 花子",
    birthday: "1975/10/02",
    number: "12345678",
    red: false,
    id: 8,
  },
  {
    date: "2021/12/21",
    time: "13:00",
    name: "山田 花子",
    birthday: "1975/10/02",
    number: "12345678",
    red: false,
    id: 9,
  },
  {
    date: "2021/12/21",
    time: "13:00",
    name: "山田 花子",
    birthday: "1975/10/02",
    number: "12345678",
    red: true,
    id: 10,
  },
];

export default function PatientList() {
  const [patientPrescription, setPatientPrescription] = useState([]);
  const [tempPatientPrescription, setTempPatientPrescription] = useState([]);
  const [status, setStatus] = useState(false);
  const [paginatedData, setPaginatedData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [countIsLinked, setCountIsLinked] = useState(0);

  // ソートラジオボタン
  // const sortRadio = [
  //   {
  //     label: "ソートしない", value: "ソートしない"
  //   },
  //   {
  //     label: "連携済み", value: "連携済み"
  //   },
  //   {
  //     label: "未連携", value: "未連携"
  //   },
  // ]

  const sortRadio = [
    {
      label: "ソートしない",
      value: 1,
    },
    {
      label: "連携済み",
      value: 2,
    },
    {
      label: "未連携",
      value: 3,
    },
  ];

  // 表示件数セレクトボックス
  const display = ["10", "50", "100", "200"];

  // ソート処理
  // const [ value, setValue ] = useState('ソートしない')
  const [value, setValue] = useState(1);
  const [patientFormData, setPatientFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [pageNumber, setPageNumber] = useState(1);
  const [pagination, setPagination] = useState();
  const [items, setItems] = useState();

  var itemPage = 10;
  var times = items / itemPage;

  const navigate = useNavigate();

  const dataCount = (event) => {
    const events = event;
    console.log("dataCount fn", events);
  };
  useEffect(() => {
    if (!getFromLocalStorage("token")) navigate("/");
  }, []);

  useEffect(() => {
    let response1;
    response1 = patientDetailsRes.patientDetails(pageNumber);
    response1.then((res) => {
      setTotalCount(res.data.count);
      setPatientPrescription(res.data.results);
      setTempPatientPrescription(res.data.results);
      setCountIsLinked(res.data.count_is_linked);
      setIsLoading((isLoading) => !isLoading);
    });
  }, [pageNumber]);

  const onChange = (page) => {
    setPageNumber(page);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    checkPatientDetails(e.target.value);
  };

  const checkPatientDetails = (patient_list) => {
    if (patient_list == 1) {
      setPatientPrescription(tempPatientPrescription);
    } else if (patient_list == 2) {
      var linked_count = tempPatientPrescription.filter(
        (item) => item.is_linked == true
      );

      setPatientPrescription(linked_count);
    } else if (patient_list == 3) {
      var not_linked_count = tempPatientPrescription.filter(
        (item) => item.is_linked == false
      );

      setPatientPrescription(not_linked_count);
    }
  };

  const handleClick = () => {
    navigate("/patient/file");
  };

  const getPatientDetails = (values) => {
    var response;
    response = patientDetailsRes.patientInfoSearch(values);
    setPatientFormData(values);

    response
      .then((res) => {
        console.log(res);

        if (res.data.length != 0) {
          setPatientPrescription(res.data);

          setTempPatientPrescription(res.data);
        } else {
          setPatientPrescription([]);
        }
      })
      .catch((err) => {
        setPatientPrescription([]);
        setTempPatientPrescription([]);
      });
  };

  console.log(patientPrescription);

  const checkLoading = () => {
    if (isLoading) {
      return (
        <p style={{ margin: "auto", fontSize: "1.5rem", color: "red" }}>
          データが見つかりませんでした
        </p>
      );
    } else return "";
  };

  const showResult = (e) => {
    var len;

    console.log(totalCount);

    if (totalCount <= e.target.value) {
      if (totalCount % 10 != 0) len = totalCount / 10 + 1;
      else len = parseInt(totalCount / 10);
    } else if (e.target.value <= totalCount) {
      if (e.target.value % 10 != 0) len = e.target.value / 10 + 1;
      else len = parseInt(e.target.value / 10);
    }
    console.log(len);
    var tempArr = [];
    var count = 0;
    for (var i = 1; i <= len; i++) {
      var response = patientDetailsRes.patientDetails(i);
      response.then((res) => {
        for (var j = 0; j < res.data.results.length; j++) {
          tempArr.push(res.data.results[j]);
        }

        count += res.data.results.length;
        console.log(count);

        if (
          count == totalCount ||
          (count < totalCount && e.target.value == count)
        ) {
          setTempPatientPrescription(tempArr);
          setPatientPrescription(tempArr);
        }
      });
    }
  };

  console.log(patientPrescription);

  return (
    <section>
      <h2 className="c-sectionTitle">
        <span>患者情報一覧</span>
      </h2>

      <table className="p-patient__table">
        <thead>
          <tr>
            <th>患者総数</th>
            <th>サービス連携済</th>
            <th>未連携</th>
            <th>サービス利用率</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {/* <td id="patient-count"> {users.all} </td>
            <td id="linked">{users.service}</td>
            <td id="not-linked">{users.not}</td>
            <td id="rate">{users.use%}</td> */}

            <td>{totalCount}</td>
            <td>{countIsLinked}</td>
            <td>{totalCount - countIsLinked}</td>
            <td>{totalCount > 0 ? Math.trunc((countIsLinked / totalCount) * 100) : 0}%</td>
          </tr>
        </tbody>
      </table>

      <SearchPatientForm url="/patient" patient={getPatientDetails} />

      <article className="p-patient__list">
        <div className="l-formInput__radio">
          {sortRadio.map((item, i) => {
            return (
              <label key={i}>
                <input
                  type="radio"
                  name="patient"
                  value={item.value}
                  onChange={handleChange}
                  checked={value == item.value ? "checked" : ""}
                />
                <span className="l-formInput__radio__text">{item.label}</span>
              </label>
            );
          })}
        </div>

        <div className="c-display">
          <p>一覧表示件数</p>
          <div className="c-selectBox__container">
            <select onChange={showResult}>
              {display.map((item, i) => {
                return (
                  <option value={item} key={i}>
                    {item}件
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <ul>
          <>
            {patientPrescription.length != 0 ? (
              <>
                {patientPrescription &&
                  patientPrescription.map((item, i) => {
                    return <List key={i} item={item} label={true} count={i} />;
                  })}
              </>
            ) : (
              checkLoading()
            )}
          </>
        </ul>
        <div className="p-patient__pagination">
          <Pagination
            // defaultCurrent={1}
            total={totalCount}
            onChange={onChange}
            //current={pageNumber}
            pageSize={10}
            showSizeChanger={false}
          />
        </div>
        <ActionButton
          text={`患者情報をCSV\nエクスポート＆インポート`}
          onClick={handleClick}
        />
      </article>
    </section>
  );
}
