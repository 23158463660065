import { Link } from "react-router-dom";
import { Link as Scroll } from "react-scroll";
import { getFromLocalStorage } from "../../../helpers/storage";

export default function Footer() {
  const footerNavs1 = [
    {
      name: "処方箋・遠隔服薬指導予約管理",
      url: "/reserve",
    },
    {
      name: "患者情報管理",
      url: "/patient",
    },
    {
      name: "OTC（薬局で購入できる医薬品）/ 医療用医薬品検索",
      url: "/medicine",
    },
  ];

  const footerNavs2 = [
    {
      name: "お知らせ",
      url: "/news",
    },
    {
      name: "お問い合わせ",
      url: getFromLocalStorage("token") === null ? "/contact" : "/inquiry",
    },
    {
      name: "利用規約",
      url: "/privacy-policy",
    },
  ];

  return (
    <footer className="l-footer">
      <Scroll to="root" smooth={true} className="l-footer__btn" />
      <div className="l-footer__logo">
        <img src="/assets/img/footer-logo.svg" alt="じぶん薬局" />
        <div className="l-footer__signOut">
          <Link
            to="/"
            onClick={() => {
              localStorage.removeItem("token");
            }}
          >
            ログアウト
          </Link>
        </div>
      </div>
      <nav>
        <ul className="l-footer__list">
          {footerNavs1.map((nav, i) => {
            return (
              <li key={i}>
                <Link to={nav.url} className="l-footer__nav">
                  {nav.name}
                </Link>
              </li>
            );
          })}
        </ul>
        <ul className="l-footer__list">
          {footerNavs2.map((nav, i) => {
            return (
              <li key={i}>
                <Link to={nav.url} className="l-footer__nav">
                  {nav.name}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
      <small>&copy; 2021 Orthrus Corporation. All Rights Reserved.</small>
    </footer>
  );
}
