import { memo, useState,useEffect } from "react";
import { reservationResObj } from "../../../apis/reservation";
import { useParams } from "react-router-dom";

const FormDateTimeInput = memo((props) => {

  const timeItems = []
  for (let i = 36; i < 84; i++) {
    const math = (number) => { return ("0" + number).slice(-2)}
    let hour = math(Math.floor(i/4))
    let minute = '00'
    switch (i - Math.floor(i/4) * 4) {
      case 0: minute = '00'
      break
      case 1: minute = '15'
      break
      case 2: minute = '30'
      break
      case 3: minute = '45'
      break
      default:
    }
    timeItems.push(`${hour}:${minute}`)
  }
  const {id}=useParams();
  
  const { label, required, errorMessage, initialValue, dateName, timeName ,text,update} = props
  const [ value, setValue ] = useState(initialValue)
  const [count,updateCount]=useState(0);

  
  


  

  const handleChange = (e) => {

    console.log("name"+e.target.name);
    console.log("value"+e.target.value)
    if(props.hasOwnProperty("method")){
      props.method(e.target.name,e.target.value)
    }
    if(e.target.name=="reservationDate" || e.target.name=="teachDate")
    
    setValue(e.target.value)
  
  }
  
//   useEffect(()=>{
//     var time;
//     if(text=="予約申請日について"){
//       time=document.getElementsByClassName("select-time")[0].value;
    
    
//   if(time && value){
//      var response;
//     //  response=reservationResObj.reservationSelect(false,time,value,id)
  
    
//    }
//   }
//   else if(text=="リモート服薬指導予約申請日について"){
     
//     if(document.getElementsByClassName("select-time")[1]!=undefined)
//      time=document.getElementsByClassName("select-time")[1].value;
     
//      if(time && value){
//       var response;
//       response=reservationResObj.medicationSelect(false,time,value,id)
   
     
//     }
   
//  }

// })



  return (
    <div className="l-formInput__date">
      <label>
        {label}
        {required && <span className="l-formInput__required">必須</span>}
      </label>
      <input 
        type="date"
        name={dateName}
        className={errorMessage ? "c-input__border error" : "c-input__border"}
        value={value}
        onChange={handleChange}
        id="Date"
      />
      <div className="c-selectBox__border black">
        <div className="c-selectBox__container">
        <select name={timeName} id="Time" onChange={handleChange}
          
         className={errorMessage ? "error" : "select-time"} >
          <option hidden value=''>時間</option>
          {timeItems.map((time, i) => {
            return (
              <option value={time} key={i}>{time}</option>
              )
            })}
        </select>
        </div>
      </div>
      {errorMessage && <span className="l-formInput__errorMessage">{errorMessage}</span>}
    </div>
  )
})

export default FormDateTimeInput