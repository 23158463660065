import { memo } from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { getAgeFromValue } from "../../../utils/hooks/getAge";
import { reservationResObj } from "../../../apis/reservation";
import moment from "moment";

const ReservationList = memo((props) => {
  // const { date, time, name, birthday, number, hospital, doctor, medicineName, red, message, remort, id } = props.item

  const [isHideen,setIsHidden]=useState(true)
  const [hiddenCount,setHiddenCount]=useState(0)

  var Prescription_date = "",
    birthday = "",
    insurer_number = "",
    isRed = "",
    medical_institution_name = "",
    prescribing_physician = "",
    prescription_details = "",
    change_to_generic_drugs = "",
    name = "";

  if (props.item.hasOwnProperty("precription")) {
    var {
      Prescription_date,
      birthday,
      insurer_number,
      isRed,
      medical_institution_name,
      prescribing_physician,
      prescription_details,
      change_to_generic_drugs,
      name,
    } = props.item.prescription[0];
  }
  const {
    medication_guidence,
    remote_medication,
    reservation_messages,
    id,
    patient,
    created_at,
    is_visited,
    is_seen_pharmacy
  } = props.item;
  var message=true;
  if (reservation_messages.length != 0) {
     if(reservation_messages[0].message=="")
     message=false;

    if (reservation_messages.length == 1) {
      if (reservation_messages[0].message == "") message = false;
    }
  }

  const creationDate = created_at.substring(0, 10);
  const time = created_at.substring(11, 16);
  const date = new Date();
  const appDate = creationDate.split("-");

  if (appDate < date.getDate()) isRed = true;
  else isRed = true;

  const [reserveReceipt, setReserveReceipt] = useState([]);

  useEffect(() => {
    var response;

    response = reservationResObj.reservationReceipt();
    response.then((res) => {
      setReserveReceipt(res.data.results);
      console.log(res.data.results);
    });
  }, []);

  const getAge = (dob) => {
    const age = getAgeFromValue(dob);
  
    if(hiddenCount==0){

      if(dob=="" || dob==null){
        setHiddenCount(hiddenCount+1)
        setIsHidden(false)
        return "";
      }
    }
  
    return age + "歳";
  };

  

  var dob="";
  if(patient.dob!=null)
  dob=patient.dob.replaceAll("-","/")

  const compareDate = moment(
    moment(props.item?.created_at).format("YYYY-MM-DD"),
    "YYYY-MM-DD"
  ).add(4, "days");

  const useDate = moment(compareDate._d).format("YYYY-MM-DD");

  const todaysDate = moment(moment().toDate()).format("YYYY-MM-DD");

  console.log("isSeenPharm",patient.name+" "+is_seen_pharmacy)
  console.log("message logic",message)

  return (
    <li
      className={
        moment(useDate).isAfter(todaysDate, "year")
          ? "c-messageList reservation red"
          : "c-messageList reservation"
      }
      name="patientBox"
      style={{ height: "auto" }}
    >
      <Link to={`/reserve/${id}`} className="label">
        <p className="c-messageList__time">
          申請日時 &#058;&nbsp; <span>{creationDate}</span>
          <span>{time}</span>
        </p>
        <div className="c-messageList__title">
          <p className="c-messageList__name">
            患者名 &#058; <span>{patient.name}</span>
            {isHideen?
            <span style={{ marginLeft: "5px" }}>
              ({dob} &nbsp;{getAge(dob)})
            </span>: ""}
          </p>
          <p className="c-messageList__number">
            保険証番号 &#058;{" "}
            <span>
              {patient.patient_insurance.length != 0
                ? patient.patient_insurance[0].insurance_number
                : ""}
            </span>
          </p>
        </div>
        <p className="c-messageList__medical">
          <span className="c-messageList__hospital">
            {medical_institution_name}
          </span>
          <span className="c-messageList__doctor">{prescribing_physician}</span>
        </p>
        <p className="c-messageList__medicine">{/* {remote_medication} */}</p>
        {message==true && is_seen_pharmacy==false && (
          <p className="c-messageList__message">患者メッセージがあります</p>
        )}
        {medication_guidence == true ? (
          <p className="c-messageList__remort"> 患者リモート服薬指導予約申請</p>
        ) : (
          ""
        )}
      </Link>
    </li>
  );
});

export default ReservationList;
