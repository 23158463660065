import axios from "axios";

var response;

var FormData = require("form-data");

const noticeapi = async () => {
  var data = new FormData();

  var config = {
    method: "get",

    url: "https://dev-api-pharmacy.automagicjapan.tech/api/notice_api/",
    headers: {
      "Content-Type": "application/json",
    },
  };

  response = await axios(config);
  return response;
};

const noticeApiId = async (id) => {
  var config = {
    method: "get",

    url: `https://dev-api-pharmacy.automagicjapan.tech/api/notice_api/${id}/`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  response = await axios(config);
  return response;
};

export const noticeResObj = {
  noticeapi,
  noticeApiId,
};
