import { useState, memo, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { FormInput, FormSelect, MainButton } from "./";
import { isChecked } from "../../../utils/validation";
import otcSearchAPI from "../../../apis/otcsearch";
import { getFromLocalStorage, setLocalStorage } from "../../../helpers/storage";
import { useContext } from "react";
import { useContextData } from "../../../useContext";

const MedicineInputForm = memo(() => {
  const [storeData, setStoreData, setDrugSearchData, drugSearchData,updateNav,setUpdateNav,userInfo,setUserInfo,searchData,setSearchData] = useContext(useContextData);
  const ocrInputRef = useRef(null);


  // チェックボックス
  const items = [
    { label: "OTC医薬品", value: "OTC医薬品" },
    { label: "医療用医薬品", value: "医療用医薬品" },
  ];

  const token = "token " + getFromLocalStorage("token");
  const headers = {};
  headers.Authorization = token;
  console.log(headers);

  // セレクトボックス
  // const medicineType = [
  //   "神経系及び感覚器官用医薬品",
  //   "個々の器官系用医薬品",
  //   "代謝性医薬品",
  //   "組織細胞機能用医薬品",
  //   "生薬及び漢方処方に基づく医薬品",
  //   "病原生物に対する医薬品",
  //   "治療を主目的としない医薬品",
  //   "麻薬",
  // ];

  // ラジオボックス
  const radioOptions = [
    {
      label: "AND",
      value: "AND",
      initial: true,
    },
    {
      label: "OR",
      value: "OR",
      initial: false,
    },
    {
      label: "NOT",
      value: "NOT",
      initial: false,
    },
  ];

  // 処方箋チェックボックス
  const [checkedItems, setCheckedItems] = useState({
    OTC医薬品: false,
    医療用医薬品: false,
  });
  const [isOcrLoading, setIsOcrLoading] = useState(false);
  const handleChange = (e) => {
    setCheckedItems({
      ...checkedItems,
      [e.target.value]: e.target.checked,
    });
  };

  // ラジオボックス
  const [value, setValue] = useState({
    effectOption: "AND",
    warningOption: "AND",
    prohibitionOption: "AND",
    jointlyOption: "AND",
  });
  const handleRadioChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });

    console.log("values input form", value);
  };

  // バリデーション
  const [errorMessages, setErrorMessages] = useState({});
  const isValidate = (values) => {
    let messages = {};

    messages.type = isChecked(values, "type")
      ? ""
      : "検索分類は必須選択項目です。";

    setErrorMessages({ ...messages });
    return Object.values(messages).every((message) => message === "");
  };

  // 検索処理
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const values = Object.fromEntries(data.entries());
    
    // チェックボックス の値取得
    if (values.type) {
      delete values.type;

      const checkedValue = Object.entries(checkedItems).reduce(
        (pre, [key, value]) => {
          value && pre.push(key);
          return pre;
        },
        []
      );

      values.type = checkedValue;
    }

    if (isValidate(values)) {
      const type = values.type.toString();

      try {

        
          

        const response = await otcSearchAPI.otcSearch(
          values.medicineName,
          values.effect,
          type,
          values.warning,
          values.campanyName,
          values.jointly,
          values.prohibition,
          values.updateStartDate,
          values.updateEndDate,
          headers,
          values.medicineType,
          1
        );
        console.log(response)

        setDrugSearchData(response);


        const medicineObj={
          medicineName: values.medicineName,
          effect:values.effect,
          type: type,
          warning:values.warning,
          companyName: values.campanyName,
          jointly: values.jointly,
          prohibition: values.prohibition,
          updateStartDate: values.updateStartDate,
          updateEndDate: values.updateEndDate,
          headers: headers
         }


         setSearchData([medicineObj])
 

        console.log("values selected", values);
        console.log("response", response);
      } catch (e) {
        console.log(e);
      }
      localStorage.setItem("drugname", values.medicineName);
      navigate("/medicine/list");
    }
  };

  const onOcrUpload = useCallback(async (e) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const fd = new FormData();
      fd.append("file", file);
      ocrInputRef.current.value = "";
      try {
        setIsOcrLoading(true);
        const res = await otcSearchAPI.otcOcrSearch(fd);
        const drugs = res.data;
        if (drugs && Array.isArray(drugs) && drugs.length) {
          setDrugSearchData(drugs);
          navigate("/medicine/list");
        } else alert("結果が見つかりません");
      } catch (err) {
        console.log(err);
      } finally {
        setIsOcrLoading(false);
      }
    }
  }, []);

  return (
    <div>
      <div className="c-actionButton">
        <button onClick={() => ocrInputRef.current.click()}>
          医薬品を撮影して検索
        </button>
        <input
          hidden
          type="file"
          accept="image/*"
          ref={ocrInputRef}
          onChange={onOcrUpload}
        />
        {isOcrLoading && (
          <div className="c-actionButton__loading">
            <span />
          </div>
        )}
      </div>
      <div className="p-searchMedicine__input__container">
        <form onSubmit={handleSubmit}>
          <div className="p-searchMedicine__input__checkbox l-formInput__checkbox">
            <p className="l-formInput__checkbox__title">
              検索分類
              <span className="l-formInput__required">必須</span>
            </p>
            <div>
              {items.map((item, i) => {
                return (
                  <label key={i}>
                    <input
                      type="checkbox"
                      name="type"
                      value={item.value}
                      onChange={handleChange}
                      checked={checkedItems[item.value]}
                      className={errorMessages.type ? "error" : ""}
                    />
                    <div className="l-formInput__checkbox__text">
                      {item.label}
                    </div>
                  </label>
                );
              })}
            </div>
            {errorMessages.type && (
              <span className="l-formInput__errorMessage">
                {errorMessages.type}
              </span>
            )}
          </div>
          <FormInput
            name="medicineName"
            label="一般名・販売名（医薬品の名称）"
            placeholder="医薬品名を入力してくださいを入力してください"
            required={false}
            type="text"
            errorMessage={
              errorMessages.medicineName ? errorMessages.medicineName : ""
            }
            border={true}
          />

          <FormInput
            name="medicineType"
            label="薬効分類(医薬品の種類)"
            placeholder="薬効分類（医薬品の種類）を選択してください"
            required={false}
            type="text"
            errorMessage={
              errorMessages.medicineType ? errorMessages.medicineType : ""
            }
            border={true}
          />
          {/* <label>
            お問い合わせカテゴリ
            <span className="l-formInput__any">任意</span>
          </label>
          <FormSelect
            name="medicineType"
            options={medicineType}
            initialValue="薬効分類（医薬品の種類）を選択してください"
            border={true}
          /> */}
          <FormInput
            name="effect"
            label="効能・効果（医薬品の効果が承認された疾病等）"
            placeholder="効能・効果を入力してください"
            required={false}
            type="text"
            border={true}
          />
          <div className="l-formInput__radio">
            {radioOptions.map((option, i) => {
              return (
                <label key={i}>
                  <input
                    type="radio"
                    name="effectOption"
                    value={option.value}
                    onChange={handleRadioChange}
                    checked={
                      value.effectOption === option.value ? "checked" : ""
                    }
                  />
                  <span className="l-formInput__radio__text">
                    {option.label}
                  </span>
                </label>
              );
            })}
          </div>
          <FormInput
            name="warning"
            label="警告（使用にあたって特に注意すべき事項等）"
            placeholder="警告を入力してください"
            required={false}
            type="text"
            border={true}
          />
          <div className="l-formInput__radio">
            {radioOptions.map((option, i) => {
              return (
                <label key={i}>
                  <input
                    type="radio"
                    name="warningOption"
                    value={option.value}
                    onChange={handleRadioChange}
                    checked={
                      value.warningOption === option.value ? "checked" : ""
                    }
                  />
                  <span className="l-formInput__radio__text">
                    {option.label}
                  </span>
                </label>
              );
            })}
          </div>
          <FormInput
            name="prohibition"
            label="禁忌（使ってはいけない状態等）"
            placeholder="禁忌を入力してください"
            required={false}
            type="text"
            border={true}
          />
          <div className="l-formInput__radio">
            {radioOptions.map((option, i) => {
              return (
                <label key={i}>
                  <input
                    type="radio"
                    name="prohibitionOption"
                    value={option.value}
                    onChange={handleRadioChange}
                    checked={
                      value.prohibitionOption === option.value ? "checked" : ""
                    }
                  />
                  <span className="l-formInput__radio__text">
                    {option.label}
                  </span>
                </label>
              );
            })}
          </div>
          <FormInput
            name="jointly"
            label="併用禁忌・併用注意（同時に使ってはいけないくすり等）"
            placeholder="併用禁忌・併用注意を入力してください"
            required={false}
            type="text"
            border={true}
          />
          <div className="l-formInput__radio">
            {radioOptions.map((option, i) => {
              return (
                <label key={i}>
                  <input
                    type="radio"
                    name="jointlyOption"
                    value={option.value}
                    onChange={handleRadioChange}
                    checked={
                      value.jointlyOption === option.value ? "checked" : ""
                    }
                  />
                  <span className="l-formInput__radio__text">
                    {option.label}
                  </span>
                </label>
              );
            })}
          </div>
          <div className="l-formInput__date">
            <label>
              更新年月日
              <span className="l-formInput__any">任意</span>
            </label>
            <div>
              <input
                type="date"
                name="updateStartDate"
                className="c-input__border"
              />
              <input
                type="date"
                name="updateEndDate"
                className="c-input__border"
              />
            </div>
          </div>
          <FormInput
            name="campanyName"
            label="問い合わせ企業名"
            placeholder="問い合わせ企業名"
            required={false}
            type="text"
            border={true}
          />
          <MainButton text="この内容で検索する" color="blue min" />
        </form>
      </div>
    </div>
  );
});

export default MedicineInputForm;
