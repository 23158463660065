import { createContext } from "react/cjs/react.production.min";
import react, { useState } from "react";

export const useContextData = createContext();

export const ContextStorage = (props) => {
  const [drugSearchData, setDrugSearchData] = useState([]);
  const [storeData, setStoreData] = useState([]);
  const [updateNav,setUpdateNav]=useState({nav: function(){}})
  const [userInfo,setUserInfo]=useState([])
  const [searchData,setSearchData]=useState([])
  return (
    <>
      <useContextData.Provider
        value={[storeData, setStoreData, setDrugSearchData, drugSearchData,updateNav,setUpdateNav,userInfo,setUserInfo,searchData,setSearchData]}
      >
        {props.children}
      </useContextData.Provider>
    </>
  );
};
