const getToken=()=>{
    
    const token=window.localStorage.getItem("token");
    return token;
}


const removeToken=()=>{
    window.localStorage.removeItem("token");
}


export const tokenObj={
    getToken,
    removeToken
}