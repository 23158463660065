import { useContext, useEffect, useMemo ,useState} from "react";
import { useNavigate } from "react-router-dom";
import { getFromLocalStorage } from "../../helpers/storage";
import { useContextData } from "../../useContext";
import otcSearchAPI from "../../apis/otcsearch";
import {
  MedicineList,
  ActionButton,
  MedicineInputForm,
} from "../components/projects";
import { Pagination } from "antd";

export default function SearchMedicineList() {
  const [storeData, setStoreData, setDrugSearchData, drugSearchData,updateNav,setUpdateNav,userInfo,setUserInfo,searchData,setSearchData] = useContext(useContextData);
  


  
  const [medicinePage, setMedicinePage] = useState({ medicine: 1 });
  const [medicineCount, setMedicineCount] = useState();

  // ダミーデータ
  const search = ["検索条件", "キーワード"];

  const onMedicine = (page) => {
    console.log(page)
    setMedicinePage({ medicine: page });
  };

  


  const items = [
    {
      searchType: "検索分類",
      drugClass: "医薬品分類",
      effectClass: "薬効分類",
      commonName: "医薬品一般名。医薬品一般名。医薬品一般名。",
      brandName: "医薬品販売名。医薬品販売名。医薬品販売名。",
      id: 1,
    },
    {
      searchType: "検索分類",
      drugClass: "医薬品分類",
      effectClass: "薬効分類",
      commonName: "医薬品一般名。医薬品一般名。医薬品一般名。",
      brandName: "医薬品販売名。医薬品販売名。医薬品販売名。",
      id: 2,
    },
    {
      searchType: "検索分類",
      drugClass: "医薬品分類",
      effectClass: "薬効分類",
      commonName: "医薬品一般名。医薬品一般名。医薬品一般名。",
      brandName: "医薬品販売名。医薬品販売名。医薬品販売名。",
      id: 3,
    },
    {
      searchType: "検索分類",
      drugClass: "医薬品分類",
      effectClass: "薬効分類",
      commonName: "医薬品一般名。医薬品一般名。医薬品一般名。",
      brandName: "医薬品販売名。医薬品販売名。医薬品販売名。",
      id: 4,
    },
    {
      searchType: "検索分類",
      drugClass: "医薬品分類",
      effectClass: "薬効分類",
      commonName: "医薬品一般名。医薬品一般名。医薬品一般名。",
      brandName: "医薬品販売名。医薬品販売名。医薬品販売名。",
      id: 5,
    },
    {
      searchType: "検索分類",
      drugClass: "医薬品分類",
      effectClass: "薬効分類",
      commonName: "医薬品一般名。医薬品一般名。医薬品一般名。",
      brandName: "医薬品販売名。医薬品販売名。医薬品販売名。",
      id: 6,
    },
    {
      searchType: "検索分類",
      drugClass: "医薬品分類",
      effectClass: "薬効分類",
      commonName: "医薬品一般名。医薬品一般名。医薬品一般名。",
      brandName: "医薬品販売名。医薬品販売名。医薬品販売名。",
      id: 7,
    },
    {
      searchType: "検索分類",
      drugClass: "医薬品分類",
      effectClass: "薬効分類",
      commonName: "医薬品一般名。医薬品一般名。医薬品一般名。",
      brandName: "医薬品販売名。医薬品販売名。医薬品販売名。",
      id: 8,
    },
    {
      searchType: "検索分類",
      drugClass: "医薬品分類",
      effectClass: "薬効分類",
      commonName: "医薬品一般名。医薬品一般名。医薬品一般名。",
      brandName: "医薬品販売名。医薬品販売名。医薬品販売名。",
      id: 9,
    },
    {
      searchType: "検索分類",
      drugClass: "医薬品分類",
      effectClass: "薬効分類",
      commonName: "医薬品一般名。医薬品一般名。医薬品一般名。",
      brandName: "医薬品販売名。医薬品販売名。医薬品販売名。",
      id: 10,
    },
  ];

  const data = useMemo(
    () =>
      (drugSearchData &&
        (Array.isArray(drugSearchData)
          ? drugSearchData
          : drugSearchData.data && drugSearchData.data.results)) ??
      [],
    [drugSearchData]
  );


  useEffect(async ()=>{
   console.log("hey")
    if(data.length!=0){
      console.log(searchData)
      const response = await otcSearchAPI.otcSearch(
        searchData[0].medicineName,
        searchData[0].effect,
        searchData[0].type,
        searchData[0].warning,
        searchData[0].companyName,
        searchData[0].jointly,
        searchData[0].prohibition,
        searchData[0].updateStartDate,
        searchData[0].updateEndDate,
        searchData[0].headers,
        medicinePage.medicine,
      );
      setDrugSearchData(response);
    }
    
  },[medicinePage])

  const navigate = useNavigate();
  useEffect(() => {
    getFromLocalStorage("token") === null ? navigate("/") : console.log("work");
  }, []);

  return (
    <section className="p-searchMedicine__input">
      <h2 className="c-sectionTitle">
        <span>OTC医薬品・医療用医薬品検索</span>
      </h2>
      <p className="p-searchMedicine__result">
        「<span>{getFromLocalStorage("drugname")}</span> 」<br />
        での検索結果一覧
      </p>
      <ul className="p-searchMedicine__list">
        {data.length ? (
          data &&
          data.map((item, i) => {
            return <MedicineList key={i} item={item} />;
          })
        ) : (
          <p style={{ margin: "auto", fontSize: "1.5rem", color: "red" }}>
            データが見つかりませんでした
          </p>
        )}
      </ul>
      <center>
      <Pagination
              // defaultCurrent={1}
              total={drugSearchData  && drugSearchData?.data && drugSearchData.data?.count && drugSearchData.data.count}
              onChange={onMedicine}
              //current={pageNumber}
              pageSize={10}
              showSizeChanger={false}
              size="large"
            />
            
            </center>
            <br/>
            <br />
      <MedicineInputForm />
    </section>
  );
}
