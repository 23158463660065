import { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { getAgeFromValue } from "../../../utils/hooks/getAge";
import { patientDetailsRes } from "../../../apis/patientDetails";
const storeId = parseInt(window.localStorage.getItem("drugstoreId"));
const PatientList = memo((props) => {
  // const { date, time, name, birthday, number, red, id } = props.item


  const [isHideen,setIsHidden]=useState(true)
  const [hiddenCount,setHiddenCount]=useState(0)

  var message = "";
  var Prescription_date = "";
  var date = "",
    time = "",
    patient_insurance = "",
    isRed = false,
    patient_id;

  var { name, dob, id } = props.item;
  patient_id = id;

  var count=props.count
  console.log("count",count)

  if (props.item.hasOwnProperty("message")) {
    var { id, message } = props.item;
    var { Prescription_date, time } = props.item.prescription[0];
    var { patient_insurance, name, dob, id: patient_id } = props.item.patient;
    var isRed = message.length != 0 ? true : false;
  }

 

  if (dob == null) 
  {
    dob = ""
   }
  else{
    dob=dob.replaceAll("-","/")
  };

  const getAge = (dob) => {
   
    
    var age = getAgeFromValue(dob);
 
    if(hiddenCount==0){
 
    if(dob=="" || dob==null){
      var age = getAgeFromValue(dob);
      setHiddenCount(hiddenCount+1)
      setIsHidden(false)
     
    return ""
    
  

  }
      
           
  return age +"歳";
    
  }
    
 
     
    
  

  };

  return (
    <>
      {console.log("props in component", props)}

      <li
        className={
          isRed ? "c-messageList patient red" : "c-messageList patient"
        }
      >
        <Link
          to={`/patient/${id}`}
          state={{ patient_id: patient_id }}
          className={props.item.is_linked && props.item.pharmacy_visited.length !== 0 && props.item.pharmacy_visited.includes(storeId) ? "useLabel" : ""}
        >
          <p className="c-messageList__time">
            最終処方日 &#058;&nbsp;{" "}
            <span>
              {Prescription_date.length != 0 ? Prescription_date : ""}
            </span>
            <span>{time}</span>
          </p>
          <div className="c-messageList__title">
            <p className="c-messageList__name">
              患者名 &#058; <span>{name}</span>
              {isHideen?
              
              <span class="dob">
                
                ({dob}&nbsp; {getAge(dob)})
              </span>
                 : ""}
            </p>
            <p className="c-messageList__number">
              保険証番号 &#058;{" "}
              <span>
                {patient_insurance.length != 0
                  ? patient_insurance[0].insurance_number
                  : ""}
              </span>
            </p>
          </div>
        </Link>
      </li>
    </>
  );
});

export default PatientList;

//new comment
