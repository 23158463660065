import { memo } from "react";
import { LinkButton } from ".";

const SignUpLink = memo(() => {
  return (
    // <article
    //   className="c-signUpLink"
    //   style={{ display: "flex", flexShrink: 0.6 }}
    // >
    //   <p></p>
    //   <LinkButton
    //     text="「じぶん薬局」に会員登録する"
    //     url="/signUp"
    //     color="red"
    //   />
    // </article>
  <span>{""}</span>
  );
});

export default SignUpLink;
