import { memo } from "react";
import { useParams } from "react-router-dom";

const Message = memo((props) => {

 var isUser=true,date="",time="";

 const url=window.location.href;
 const path=new URL(url);
 const {id}=useParams()


 if(props.item!=undefined && path.pathname!=`/patient/1/history/${id}`){

var {message,created_at,sender}=props.item;

  // date=created_at.substring(0,10);
  // time=created_at.substring(11,16);

  if(sender!=undefined){
   
  if(sender.email=="admin@admin.com")
  isUser=false;
  }
}
if(created_at!=null || created_at==""){
  date=created_at.substring(0,10)
  time=created_at.substring(11,16)
}


var is_seen=true;




if(!props.isSeen)
is_seen=false


 



    
return (
  <>{message!="" && !is_seen?
    <li className={isUser? "user" : ""}>
      <div className="l-message__message">
        <p className="l-message__date">
          {date} 
          <span>{time}</span>
        </p>
        <p className="l-message__text">{message}</p>
      </div>
    </li>: ""}
    </>
  )
})

export default Message