import axios from "axios";

const template = async () => {
  const token = "token" + " " + localStorage.getItem("token");
  var res;
  var config = {
    method: "get",
    url: "https://dev-api-pharmacy.automagicjapan.tech/api/template/",
    headers: {
      Authorization: token,
    },
  };
  res = await axios(config);

  return res;
};
const createTemplate = async (values, id) => {
  var res;
  const token = "token" + " " + localStorage.getItem("token");
  const data = JSON.stringify({
    title: values.templateTitle,
    body: values.templateText,
    user: 1,
  });

  const config = {
    method: "post",
    url: "https://dev-api-pharmacy.automagicjapan.tech/api/template/",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  var res = await axios(config);
  return res;
};

const editTemplate = async (values, id) => {
  var res;
  const token = "token" + " " + localStorage.getItem("token");
  const data = JSON.stringify({
    title: values.templateTitle,
    body: values.templateText,
  });

  const config = {
    method: "put",
    url: `https://dev-api-pharmacy.automagicjapan.tech/api/template/${id}/`,
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  var res = await axios(config);
  return res;
};

const sendMessage = async (
  message,
  reserveDate,
  remoteDate,
  reserveAccepted,
  remoteAccepted,
  originalReserveDate,
  originalGuidanceDate,
  id,
  option_val,
  reserveAcceptByPharma,
  remoteAcceptByPharma
) => {
  var response;
  const token = "token" + " " + localStorage.getItem("token");
  //is_accepted: is_accepted,
  // reservation_scheduled_time : reservation_date,
  // is_remote_accepted : is_remote_accepted,
  // guidance_scheduled_time : remote_med_date,
  // template : option_val,
  // message: message
  const newData = {
    reservation_scheduled_time: "",
    is_remote_accepted: "",
    guidance_scheduled_time: "",
    is_seen_pharmacy: true,

    message: message,
  };

  if (
    reserveDate.date == "" &&
    reserveDate.time == "" &&
    remoteDate.date == "" &&
    remoteDate.time == ""
    ||
    (reserveAcceptByPharma && remoteAcceptByPharma)
  ) {
    newData.is_accepted = true;
    newData.is_remote_accepted = true;
    newData.message = message;
    newData.reservation_scheduled_time = originalReserveDate;
    newData.guidance_scheduled_time = originalGuidanceDate;
  } else if (
    reserveDate.date != "" &&
    reserveDate.time != "" &&
    remoteDate.date == "" &&
    remoteDate.time == ""
  ) {
    newData.is_accepted = false;
    newData.is_remote_accepted = true;
    newData.message = message;
    newData.reservation_scheduled_time =
      reserveDate.date + "T" + reserveDate.time + ":00Z";
    newData.guidance_scheduled_time = originalGuidanceDate;
  } else if (
    reserveDate.date == "" &&
    reserveDate.time == "" &&
    remoteDate.date != "" &&
    remoteDate.time != ""
  ) {
    newData.is_accepted = true;
    newData.is_remote_accepted = false;
    newData.message = message;
    newData.reservation_scheduled_time = originalReserveDate;
    newData.guidance_scheduled_time =
      remoteDate.date + "T" + remoteDate.time + ":00Z";
  } else if (
    reserveDate.date != "" &&
    reserveDate.time != "" &&
    remoteDate.date != "" &&
    remoteDate.time != ""
  ) {
    newData.is_accepted = false;
    newData.is_remote_accepted = false;
    newData.message = message;
    newData.reservation_scheduled_time =
      reserveDate.date + "T" + reserveDate.time + ":00Z";
    newData.guidance_scheduled_time =
      remoteDate.date + "T" + remoteDate.time + ":00Z";
  }

  const data = JSON.stringify(newData);

  const config = {
    method: "put",
    url: `https://dev-api-pharmacy.automagicjapan.tech/api/for_reservation/${id}/`,
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  response = await axios(config);

  return response;
};

const getTemplateInfo = async (id) => {
  const token = "token" + " " + localStorage.getItem("token");
  var res;
  res = await axios.get(`https://dev-api-pharmacy.automagicjapan.tech/api/template/${id}/`, {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  });

  return res;
};

export const templateResObj = {
  template,
  createTemplate,
  editTemplate,
  sendMessage,
  getTemplateInfo,
};
