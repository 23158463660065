import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { HistoryList as List } from "../components/projects";
import { getDateFromValue, getAgeFromValue } from "../../utils/hooks";
import { patientDetailsRes } from "../../apis/patientDetails";
import { getFromLocalStorage } from "../../helpers/storage";

// ダミーデータ
const item = {
  number: "XXXXXXXX",
  userName: "XXXXXXXX",
  birthday: "1975/10/02",
};

const lists = [
  {
    date: "2021/12/21",
    time: "13:00",
    hospital: "医療機関名",
    doctor: "担当医師名",
    medicineName:
      "おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。",
    id: 1,
  },
  {
    date: "2021/12/21",
    time: "13:00",
    hospital: "医療機関名",
    doctor: "担当医師名",
    medicineName:
      "おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。",
    id: 2,
  },
  {
    date: "2021/12/21",
    time: "13:00",
    hospital: "医療機関名",
    doctor: "担当医師名",
    medicineName:
      "おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。",
    id: 3,
  },
  {
    date: "2021/12/21",
    time: "13:00",
    hospital: "医療機関名",
    doctor: "担当医師名",
    medicineName:
      "おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。",
    id: 4,
  },
  {
    date: "2021/12/21",
    time: "13:00",
    hospital: "医療機関名",
    doctor: "担当医師名",
    medicineName:
      "おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。",
    id: 5,
  },
  {
    date: "2021/12/21",
    time: "13:00",
    hospital: "医療機関名",
    doctor: "担当医師名",
    medicineName:
      "おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。",
    id: 6,
  },
  {
    date: "2021/12/21",
    time: "13:00",
    hospital: "医療機関名",
    doctor: "担当医師名",
    medicineName:
      "おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。",
    id: 7,
  },
  {
    date: "2021/12/21",
    time: "13:00",
    hospital: "医療機関名",
    doctor: "担当医師名",
    medicineName:
      "おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。",
    id: 8,
  },
  {
    date: "2021/12/21",
    time: "13:00",
    hospital: "医療機関名",
    doctor: "担当医師名",
    medicineName:
      "おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。",
    id: 9,
  },
  {
    date: "2021/12/21",
    time: "13:00",
    hospital: "医療機関名",
    doctor: "担当医師名",
    medicineName:
      "おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。",
    id: 10,
  },
];

export default function History() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [presHistory, setPresHistory] = useState([{ birthday: "" }]);
  const [noPresResults, setNoPresResults] = useState(true);

  const [enable, setEnable] = useState(false);

  useEffect(() => {
    getFromLocalStorage("token") === null ? navigate("/") : console.log("work");
    var response;
    response = patientDetailsRes.prescriptionDetails(id);
    response.then((res) => {
      console.log(res);
      setPresHistory(res.data);
    });
  }, []);

  console.log(presHistory)

  return (
    <section>
      <h2 className="c-sectionTitle">
        <span>処方受け取り予約申請</span>
      </h2>
      <dl className="l-table">
        <div>
          <dt>保険者番号</dt>
          {presHistory.length != 0 ? (
            <dd>
              {presHistory.map((item) => {
                var Insnumber = "";
                Insnumber += item.insurer_number + " , ";
                return Insnumber;
              })}
            </dd>
          ) : (
            ""
          )}
        </div>
        <div>
          <dt>お名前</dt>
          {presHistory.length != 0 || presHistory.length == null ? (
            <dd>
              {presHistory.map((item) => {
                var Name = "";
                Name += item.name + " , ";
                return Name;
              })}
            </dd>
          ) : (
            ""
          )}
        </div>
        <div>
          <dt>生年月日</dt>
          <dd>
            {presHistory.length != 0
              ? getDateFromValue(presHistory[0].birthday)
              : ""}
            （
            {presHistory.length != 0
              ? getAgeFromValue(presHistory[0].birthday) + "歳"
              : ""}
            ）
          </dd>
        </div>
      </dl>
      <article className="p-history__list">
        <h3>これまでの処方履歴</h3>
        <ul>
          {presHistory.length != 0 ? (
            <>
              {presHistory.map((item, i) => {
                return <List key={i} item={item} patientId={item.id} />;
              })}{" "}
            </>
          ) : (
            <p style={{ color: "red", margin: "auto" }}>
              データが見つかりませんでした
            </p>
          )}
        </ul>
      </article>
      <div className="c-linkText bold">
        <button onClick={() => navigate(-1)}>詳細に戻る</button>
      </div>
    </section>
  );
}
