// for storage

const setLocalStorage = ({ token }) => {
  localStorage.setItem("token", token);
};

const getFromLocalStorage = (key) => {
  let storedValue = null;
  try {
    storedValue = localStorage.getItem(key);
  } catch (e) {
    console.log("error", e);
  }
  return storedValue;
};

export { setLocalStorage, getFromLocalStorage };
