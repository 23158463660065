import { memo, useState } from "react";

const FormSelect = memo((props) => {

  const { name, errorMessage, initialValue, options, border } = props

  // セレクトボックスのcss変更
  const [ selectChange, setSelectChange ] = useState(false)
  const handleSelectChange = () => {
    setSelectChange(true)
  }

  return (
    <div className={`c-selectBox ${border && "border"}`}>
      <div className="c-selectBox__container">
        <select name={name} className={`${errorMessage ? "error" : ""} ${selectChange ? "selected" : ""}`} onChange={handleSelectChange}>
        <option hidden value=''>{initialValue}</option>
          {options.map((item, i) => {
            return (
              <option value={item} key={i}>{item}</option>
              )
            })}
        </select>
      </div>
      {errorMessage && <span className="l-formInput__errorMessage">{errorMessage}</span>}
    </div>
  )
})

export default FormSelect