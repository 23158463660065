import { memo } from 'react'
import { Link } from 'react-router-dom'

const MedicineList = memo(props => {
  const {
    drug_classification,

    brand_name,
    id,
    efficacy,
    drug_name,
  } = props.item
  // const notused = [effectClass,commonName,brand name]

  return (
    <li>
      <Link to={`/medicine/${id}`}>
        <p className='type'>
          <span>{'Search Classification'}</span>
          <span>
            {drug_classification?.drug_classification_jp || ""}
          </span>
          <span>{efficacy}</span>
        </p>
        <p>一般名 &#058;&nbsp;{drug_name}</p>
        <p>販売名 &#058;&nbsp;{brand_name}</p>
      </Link>
    </li>
  )
})

export default MedicineList
