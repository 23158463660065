import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import { FormDateInput, FormSelect, FormInput, MainButton } from ".";

const SearchPatientForm = memo((props) => {
  const { url, patient } = props;

  // カテゴリーセレクトボックス
  const category = [
    "初来訪のみ",
    "再訪のみ",
    "処方受け取り予約未対応のみ",
    "申請メッセージのある患者のみ",
    "リモート服薬指導実施者のみ",
  ];

  // 検索処理
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const values = Object.fromEntries(data.entries());

    console.log("values", values);
    setStartDate(values.startDate);
    setEndDate(values.endDate);

    patient(values);
    navigate(url);
  };
  const [startDate, setStartDate] = useState("YYYY年MM月DD日");
  const [endDate, setEndDate] = useState("YYYY年MM月DD日");

  return (
    <div className="c-searchPatientForm__form">
      <form className="l-formInput" onSubmit={handleSubmit}>
        <div className="l-formInput__form c-searchPatientForm__date">
          <label>
            検索期間 &#058;{startDate}〜{endDate}
          </label>
          <div>
            <FormDateInput required={false} name="startDate" />
            <FormDateInput required={false} name="endDate" />
          </div>
        </div>
        <div className="l-formInput__form">
          <label>絞り込みカテゴリ</label>
          <FormSelect
            name="category"
            options={category}
            initialValue="絞り込みカテゴリを選択してください"
          />
        </div>
        <FormInput
          name="keyword"
          label="患者名など"
          placeholder="患者名などをフリーワードで入力してください"
          required={false}
          type="keyword"
          autocomplete="keyword"
        />
        <MainButton text="この内容で処方箋予約を検索" color="blue min" />
      </form>
    </div>
  );
});

export default SearchPatientForm;

// （YYYY年MM月DD日〜YYYY年MM月DD日）
