import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  FormRadio,
  LinkText,
  Message,
  MainButton,
  HistoryList,
} from "../components/projects";
import { getDateFromValue, getAgeFromValue } from "../../utils/hooks";
import { historyResObj } from "../../apis/presciptionHistory";
import { getFromLocalStorage } from "../../helpers/storage";

// ダミーデータ
const item = {
  userId: 1,
  remortDate: "2021/12/21",
  remortTime: "13:00",
  status: "発行済み",
  prescriptionDate: "2021/10/02",
  limit: "2022/10/02",
  number: "XXXXXXX",
  userName: "XXXXXXX",
  birthday: "1975/05/10",
  phoneNumber: "080-1234-5678",
  email: "XXXXXXX@XXX.com",
  remort: true,
  teachDate: "2021/12/21",
  teachTime: "13:00",
  memo: "服薬指導メモ詳細。服薬指導メモ詳細。服薬指導メモ詳細。服薬指導メモ詳細。服薬指導メモ詳細。服薬指導メモ詳細。服薬指導メモ詳細。服薬指導メモ詳細。服薬指導メモ詳細。服薬指導メモ詳細。服薬指導メモ詳細。服薬指導メモ詳細。服薬指導メモ詳細。服薬指導メモ詳細。服薬指導メモ詳細。服薬指導メモ詳細。服薬指導メモ詳細。",
  gender: "女",
  drugHospital: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
  contact: "03-123-5678",
  drugConctor: "XXXXXXXXXXXXXXXXXXXXXXXX",
  detail: [
    "お薬の名前",
    "お薬の形（錠剤、カプセル剤など）",
    "お薬の量　1回あたりに飲む量",
    "1日に飲む回数",
    "飲むタイミング",
  ],
  change: true,
  time: 3,
};

// メッセージ履歴
const messages = [
  {
    date: "2021/12/22",
    time: "14:55",
    text: "患者からの予約申請メッセージ。患者からの予約申請メッセージ。患者からの予約申請メッセージ。患者からの予約申請メッセージ。患者からの予約申請メッセージ。患者からの予約申請メッセージ。患者からの予約",
    isUser: true,
  },
  {
    date: "2021/12/22",
    time: "14:55",
    text: "薬局・薬店からの予約申請返信メッセージ。薬局・薬店からの予約申請返信メッセージ。薬局・薬店からの予約申請返信メッセージ。薬局・薬店からの予約申請返信メッセージ。",
    isUser: false,
  },
];

const lists = [
  {
    date: "2021/12/21",
    time: "13:00",
    hospital: "医療機関名",
    doctor: "担当医師名",
    medicineName:
      "おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。",
    id: 1,
  },
  {
    date: "2021/12/21",
    time: "13:00",
    hospital: "医療機関名",
    doctor: "担当医師名",
    medicineName:
      "おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。",
    id: 2,
  },
  {
    date: "2021/12/21",
    time: "13:00",
    hospital: "医療機関名",
    doctor: "担当医師名",
    medicineName:
      "おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。",
    id: 3,
  },
  {
    date: "2021/12/21",
    time: "13:00",
    hospital: "医療機関名",
    doctor: "担当医師名",
    medicineName:
      "おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。",
    id: 4,
  },
];

export default function History() {
  const navigate = useNavigate();

  const [guidanceAppDate, setGuidanceAppDate] = useState("");
  const [guidanceSchDate, setGuidanceSchDate] = useState("");
  const [guidanceAppTime, setGuidanceAppTime] = useState("");
  const [guidanceSchTime, setGuidanceTime] = useState("");

  const { id } = useParams();

  // 予約申請日
  const statusRadio = [
    {
      label: "未発行",
      value: "未発行",
      initial: false,
    },
    {
      label: "発行済み",
      value: "発行済み",
      initial: true,
    },
  ];

  const handleClick = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const values = Object.fromEntries(data.entries());

    console.log(values);
  };

  const line = () => {
    window.open("https://line.me/R/nv/chat");
  };

  // メッセージ追加取得
  const [addition, setAddition] = useState(false);
  const fetchMoreMessage = () => {
    setAddition((prevState) => !prevState);
  };

  const [historyDetails, setHistoryDetails] = useState({
    memos: [{ guidance_scheduled_time: "", created_at: "" }],
    patient: { name: "" },
  });

  const [enable, setEnable] = useState(false);

  useEffect(() => {
    getFromLocalStorage("token") === null ? navigate("/") : console.log("work");

    var response;
    response = historyResObj.getHistory(id);
    response.then((res) => {
      console.log(res);
      setHistoryDetails(res.data);

      if (res.data.memos.length != 0) {
        var schDate = res.data.memos[0].memo_scheduled_time.substring(0, 10);

        var createDate = res.data.memos[0].created_at.substring(0, 10);
        var createTime = res.data.memos[0].created_at.substring(11, 16);

        setGuidanceAppDate(createDate);
        setGuidanceSchDate(schDate);
      }
    });
  }, []);

  return (
    <section>
      <h2 className="c-sectionTitle">
        <span>処方受け取り予約</span>
      </h2>
      <form onSubmit={handleClick}>
        <div className="p-reserve__form">
          <div className="l-formInput">
            <FormRadio
              name="status"
              title="処方箋ステータス"
              options={statusRadio}
              required={true}
              initial={1}
            />
          </div>
        </div>
        <dl className="l-table">
          <div>
            <dt>リモート服薬指導予約日</dt>
            <dd>
              <>
                {guidanceSchDate != "" ? guidanceSchDate : ""}

                <span className="space">{""}</span>
              </>
            </dd>
          </div>
        </dl>
        <div className="p-reserve__form">
          <div className="l-formInput">
            <div className="p-reserve__lineButton">
              <button
                onClick={() => {
                  line();
                }}
              >
                LINE通話でリモート服薬指導を始める
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingBottom: "15px",
            }}
          >
            <p
              style={{
                // marginTop: "-26px",
                paddingLeft: "27px",
                paddingRight: "27px",
                font: "11px",
                fontFamily: "Noto Sans JP",
                fontWeight: "400",
                paddingBottom: "0%",
                marginBottom: "0%",
              }}
            >
              ※遠隔服薬指導時にはLINEを利用致します。
            </p>
            <p
              style={{
                paddingLeft: "12px",
                paddingRight: "4px",
                font: "11px",
                fontFamily: "Noto Sans JP",
                fontWeight: "400",
              }}
            >
              薬局とLINEIDの交換を事前にメッセージでお済ませください。
            </p>
          </div>
          <div className="l-formInput__form border">
            <label>
              服薬指導メモ
              <span className="l-formInput__any">任意</span>
            </label>
            <textarea
              name="memo"
              placeholder="服薬指導メモを入力してください"
              className="c-textarea__border"
            />
          </div>
        </div>
      </form>

      <article className="p-history__table">
        <dl className="l-table">
          <div>
            <dt>発行ステータス</dt>
            <dd>{item.status}</dd>
          </div>
          <div>
            <dt>処方年月日</dt>
            <dd>
              {getDateFromValue(
                historyDetails.Prescription_date != null
                  ? historyDetails.Prescription_date
                  : ""
              )}
              <span className="l-table__limit">
                有効期限 &#058;{" "}
                {getDateFromValue(
                  historyDetails.Prescription_date != null
                    ? historyDetails.Prescription_date
                    : ""
                )}
                まで
              </span>
            </dd>
          </div>
          <div>
            <dt>保険者番号</dt>
            <dd>
              {historyDetails.insurer_number != null
                ? historyDetails.insurer_number
                : ""}
            </dd>
          </div>
          <div>
            <dt>お名前</dt>
            <dd>{historyDetails.name != null ? historyDetails.name : ""}</dd>
          </div>
          <div>
            <dt>生年月日</dt>
            <dd>
              {getDateFromValue(
                historyDetails.birthday != null ? historyDetails.birthday : ""
              )}
              （
              {getAgeFromValue(
                historyDetails.birthday != null ? historyDetails.birthday : ""
              )}
              歳）
            </dd>
          </div>
          <div>
            <dt>電話</dt>
            <dd>
              {historyDetails.contact != null ? historyDetails.contact : ""}
            </dd>
          </div>
          <div>
            <dt>メールアドレス</dt>
            {historyDetails.hasOwnProperty("patient") ? (
              <>
                <dd>{historyDetails.patient.email}</dd>
              </>
            ) : (
              ""
            )}
          </div>
        </dl>
        <LinkText
          text="この患者の詳細情報を見る"
          url={`/patient/${historyDetails.patient.id}`}
          className="bold"
          patientId={historyDetails.patient.id}
        />
      </article>

      <article className="p-reserve__attention">
        <p>
          今回の処方箋にはアレルギーとなる薬剤が含まれている可能性があります。処方時にはご注意ください。
        </p>
      </article>

      <dl className="l-table">
        <div>
          <dt>処方年月日</dt>
          <dd>
            {getDateFromValue(
              historyDetails.Prescription_date != null
                ? historyDetails.Prescription_date
                : ""
            )}
          </dd>
        </div>
        <div>
          <dt>リモート服薬指導</dt>
          <dd>{item.remort ? "希望する" : "希望しない"}</dd>
        </div>
        <div className="last">
          <dt>指導予約申請日</dt>
          <dd>
            <>
              {guidanceAppDate != "" ? guidanceAppDate : ""}
              <span className="space">{""}</span>
            </>
          </dd>
        </div>
        <div>
          <dt>服薬指導メモ</dt>
          {/* <dd>{item.memo}</dd> */}
          <dd>{""}</dd>
        </div>
      </dl>

      <article className="l-message">
        <h3>これまでのメッセージ</h3>

        <>
          <ul>
            {messages.map((item, i) => {
              return <Message key={i} item={item} />;
            })}
          </ul>
          {!addition && (
            <MainButton
              text="メッセージをもっと見る"
              color="message"
              onClick={fetchMoreMessage}
            />
          )}
          {addition && (
            <ul>
              {messages.map((item, i) => {
                return <Message key={i} item={item} />;
              })}
            </ul>
          )}
        </>
      </article>

      <dl className="l-table">
        <div>
          <dt>性別</dt>
          <dd>{historyDetails.gender != null ? historyDetails.gender : ""}</dd>
        </div>
        <div>
          <dt>医療機関名</dt>
          <dd>
            {historyDetails.medical_institution_name != null
              ? historyDetails.medical_institution_name
              : ""}
          </dd>
        </div>
        <div>
          <dt>連絡先</dt>
          <dd>
            {historyDetails.contact != null ? historyDetails.contact : ""}
          </dd>
        </div>
        <div>
          <dt>処方担当医師</dt>
          <dd>
            {historyDetails.prescribing_physician
              ? historyDetails.prescribing_physician
              : ""}
          </dd>
        </div>
        <div>
          <dt>処方詳細</dt>
          {/* {item.detail.map((item, i) => {
            return (
              <dd key={i}>{item}</dd>
            )
          })} */}
          {historyDetails.prescription_details
            ? historyDetails.prescription_details
            : ""}
        </div>
        <div>
          <dt>ジェネリック医薬品への変更</dt>

          <dd>
            {historyDetails.change_to_generic_drugs != "possible"
              ? "変更可"
              : "変更不可"}
          </dd>
        </div>
        <div className="last">
          <dt>分割調剤の回数</dt>
          <dd>
            {historyDetails.number_of_split_dispensing != null
              ? historyDetails.number_of_split_dispensing
              : ""}
            回
          </dd>
        </div>
        <div>
          <dt>処方箋画像</dt>
          <dd>
            {/* <div className="l-table__img"> */}
            <img
              src={historyDetails.prescription_image}
              style={{ width: "200px", height: "200px" }}
              alt="prescription-image"
            />
            {/* </div> */}
          </dd>
        </div>
      </dl>

      <article className="p-history__list">
        <h3>これまでの処方履歴</h3>
        {/* <ul>
          {lists.map((item, i) => {
            return (
              <HistoryList
                key={i}
                item={item}
              />
            )
          })}
        </ul> */}
        <div className="c-linkText bold">
          <button onClick={() => navigate(-1)}>
            この患者の処方履歴一覧を見る
          </button>
        </div>
      </article>

      <LinkText text="処方履歴一覧に戻る" url="/reserve" className="bold" />
    </section>
  );
}
//new comment
//addding comment
