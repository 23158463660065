// ナビゲーション部分
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function HeaderNav() {
  const [navCount, setNavCount] = useState(0);

  const updateNav = () => {
    setNavCount(navCount + 1);
  };

  const path = window.location.pathname;

  // 未ログイン時のナビゲーションリスト
  const lists = [
    {
      text: "処方箋・遠隔服薬指導予約管理",
      url: path != "/" ? "/reserve" : "/",
    },
    {
      text: "患者情報管理",
      url: path != "/" ? "/patient" : "/",
    },
    {
      text: "OTC / 医療用医薬品検索",
      url: path != "/" ? "/medicine" : "/",
    },
    {
      text: "お問い合わせ",
      url: path != "/" ? "/inquiry" : "/contact",
    },
  ];

  // メニューの開閉
  const [openMenu, setOpenMenu] = useState(false);
  const handleClickBtn = () => {
    setOpenMenu((prevState) => !prevState);
  };

  return (
    <div className="l-headerNav">
      <div>
        <button className="l-headerNav__openBtn" onClick={handleClickBtn}>
          <span>MENU</span>
        </button>
      </div>

      <nav className={openMenu ? "l-headerNav__open" : ""}>
        <p>MENU</p>
        <button
          className="l-headerNav__closeBtn"
          onClick={handleClickBtn}
        ></button>
        <ul className="l-headerNav__list">
          <li>
            <Link
              to="/"
              onClick={() => {
                localStorage.removeItem("token");
              }}
            >
              ログアウト
            </Link>
          </li>
          {lists.map((list, i) => {
            return (
              <li key={i} onClick={updateNav}>
                <Link to={list.url}>
                  <span>{list.text}</span>
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
}

//comment added
