import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useContextData } from "../../../useContext";
import { useContext } from "react";
import { tokenObj } from "../../../apis/get_token";

export default function Header() {
  const [
    storeData,
    setStoreData,
    setDrugSearchData,
    drugSearchData,
    updateNav,
    setUpdateNav,
  ] = useContext(useContextData);
  const [count, setCount] = useState(0);

  const token = tokenObj.getToken();
  const path = window.location.pathname;
  console.log(path);

  // if(path=="/"){
  //   tokenObj.removeToken();
  // }
  console.log(path);

  const lists = [
    {
      text: "処方箋・遠隔服薬指導予約管理",
      link: path != "/" ? "/reserve" : "/",
    },
    {
      text: "患者情報管理",
      link: path != "/" ? "/patient" : "/",
    },
    {
      text: "OTC / 医療用医薬品検索",
      link: path != "/" ? "/medicine" : "/",
    },
    {
      text: "OTC / お問い合わせ",
      link: path != "/" ? "/inquiry" : "/contact",
    },
  ];

  const updateNavbar = () => {
    setCount(count + 1);
  };

  useEffect(() => {
    setUpdateNav({ nav: updateNavbar });
    updateNavbar();
    // window.location.href="/";
    if (token == null) document.getElementById("logout").style.display = "none";
    else document.getElementById("logout").style.display = "block";
  }, []);

  console.log("header");

  return (
    <header className="l-header">
      <div className="l-header__logo">
        <h1>
          <Link to="/">
            <img src="/assets/img/header-logo.svg" alt="じぶん薬局" />
          </Link>
        </h1>
        <span>for pharmacy </span>
      </div>
      <nav className="l-header__nav">
        <ul>
          <li>
            <Link
              to="/"
              onClick={() => {
                localStorage.removeItem("token");
                localStorage.removeItem("drugstoreId")
                window.location.href = "/";
              }}
              id="logout"
            >
              ログアウト
            </Link>
          </li>
          {lists.map((item, i) => {
            return (
              <li key={i}>
                <Link
                  to={token != null ? item.link : "/"}
                  onClick={updateNavbar}
                >
                  {item.text}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </header>
  );
}
