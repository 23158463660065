import axios from 'axios'

const otcSearch = (
  medicineName,
  effect,
  type,
  warning,
  campanyName,
  jointly,
  prohibition,
  updateStartDate,
  updateEndDate,
  headers,
  medicineType,
  pageNo,
) =>
  axios.get(
    `https://dev-api-pharmacy.automagicjapan.tech/api/pharma_otc/search/?page=${pageNo}&drug_efficasy_classification=${medicineType}&generic_name=${medicineName}&effects=${effect}&contact_category=${type}&warning=${warning}&inquiry_company_name=${campanyName}&concomitant=${jointly}&contraindications=${prohibition}&start_update_date=${updateStartDate}&end_updated_date=${updateEndDate}`,
    { headers: headers }
  )
const otcOcrSearch = data => {
  const token = 'token ' + localStorage.getItem('token')
  return axios.post(
    `https://dev-api-pharmacy.automagicjapan.tech/api/drug_ocr/search/`,
    data,
    {
      headers: { Authorization: token },
    }
  )
}

const otcMedicineDetails = async id => {
  const token = 'token' + ' ' + localStorage.getItem('token')
  var response
  response = await axios.get(
    `https://dev-api-pharmacy.automagicjapan.tech/api/pharma_otc_info/${id}/`,
    {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    }
  )

  return response
}

const otcSearchAPI = {
  otcSearch,
  otcMedicineDetails,
  otcOcrSearch,
}

export default otcSearchAPI
