import { Link, useLocation } from "react-router-dom";
import { useContext, useState,useEffect, useDebugValue } from "react";
import { useContextData } from "../../../useContext";
import {imgResObj} from "../../../apis/imgAPI"
import moment from "moment";
import img from "../../../assets/img/top-dammy.jpg"







// ダミーデータ
// const user = {
//   store: 'XX薬局',
//   userName: 'XXXX',
//   status: '有料プランA'
// }
const user = {
  store: "",
  userName: "",
  status: "",
};

export default function Layout() {

  const [userInfo,setUserInfo]=useContext(useContextData)

  
  
  const path = window.location.pathname;

  console.log(userInfo);

  const email = window.localStorage.getItem("name");
  const drugstore=window.localStorage.getItem("drugstore");
  console.log(email);
  console.log(drugstore)
  var name = "";
  var drugStoreName="";
  if (email!= undefined && drugstore!=undefined && path != "/") {
    var emailSplit = email?.split("@");
    console.log("emailsplit", emailSplit);
    name = emailSplit[0];
    name = name?.charAt(0).toUpperCase() + "" + name.slice(1);
    drugStoreName=drugstore;

    
  }


  const [getImg,setGetImg]=useState([])

  const pcItems = [
    {
      text: "処方箋・遠隔服薬指導予約管理",
      url: path != "/" ? "/reserve" : "/",
    },
    {
      text: "患者情報管理",
      url: path != "/" ? "/patient" : "/",
    },
    {
      text: `OTC（薬局で購入できる医薬品 /\n医療用医薬品検索`,
      url: path != "/" ? `/medicine` : "/",
    },
  ];

  const spItems = [
    {
      text: `処方箋・服薬\n指導管理`,
      url: "/reserve",
    },
    {
      text: "患者情報管理",
      url: "/patient",
    },
    {
      text: `OTC/医療用\n医薬品検索`,
      url: `/medicine`,
    },
  ];
  var tempArr=[]

  useEffect(()=>{

  
      var response=imgResObj.getImage() ;
      var dated=new Date()
      var month=dated.getMonth()+1
      var today=dated.getFullYear()+"-"+month+"-"+dated.getDate()
      console.log(today)
    

      
      response.then((res)=>{
        for(var i=0;i<res.data.results.length;i++){


     
          var startDate=res.data.results[i].post_period_start;
          var endDate=res.data.results[i].post_period_end;

          console.log("startDate",startDate)

          if(moment(today).isSameOrAfter(startDate) && moment(today).isSameOrBefore(endDate)){
           tempArr.push(res.data.results[i]);
         

       
          }
        }

        var randNum=parseInt(Math.random()*tempArr.length-1)
   
         for(var i=0;i<tempArr.length;i++){
          if(i==randNum){
            setGetImg([tempArr[i]])
          }
         }
       

       
        // (moment(today).diff(date, "days") >= 4) 
      })
  },[])

  useEffect(()=>{

    setInterval(()=>{
      var randNum=parseInt(Math.random()*tempArr.length-1)
     
      for(var i=0;i<tempArr.length;i++){
       if(i==randNum){
         setGetImg([tempArr[i]])
       }
      }
    
    },10000)
  })



  console.log(getImg[0])
  

  const location = useLocation();

  return (
    <section className="l-top">
      <div className="l-top__top" >
      
        <div className="l-top__img" style={{height: "40vh"}}>
          {getImg.length!=0?<>
          {(getImg[0].advertising_text==null || getImg[0].advertising_text=="")?
          
          <a href={getImg[0].link_destination}  target="_blank">
          <img src={getImg[0].banner}  alt="じぶん薬局" />
          </a>
         
          
          : <><img src={img}  alt="じぶん薬局" />
   
          </>}
          </>: ""}
        </div>
        

        {getImg.length!=0 && (getImg[0].banner==null) && path!="/" ?
        <div className="l-top__user">
          <a href={getImg[0].link_destination} target="_blank"><p className="l-top__user__name">{getImg[0].advertising_text}</p></a>
        
        </div> : ""}
        
       
        <div className="l-top__user">
          {!location.pathname.includes("/sign") && (
            <p>
              <span className="l-top__user__store"></span>
              <>
                {path != "/" ? (
                  <span className="l-top__user__name">
                    {drugStoreName} 薬局 &nbsp;ご担当 &#058; &nbsp;{name} 様
                  </span>
                ) : (
                  <>
                  {getImg.length!=0 && (getImg[0].banner==null) && path=="/" ?
                      <span className="l-top__user__name">
                    <a href={getImg[0].link_destination} target="_blank"><span className="l-top__user__name">{getImg[0].advertising_text}</span></a>
        
                    </span> : ""}</>
        
                )}
              </>
              {/* <span className="l-top__user__name">ご担当 &#058; {user.userName} </span> */}

              {/* <span className="l-top__user__status">ご利用ステータス &#058; {user.status}</span> */}
            </p>
          )}
        </div>
      </div>

      <nav className="l-top__nav">
        <ul className="pc-only">
          {pcItems.map((item, i) => {
            return (
              <li key={i}>
                <Link to={item.url}>
                  <span>{item.text}</span>
                </Link>
              </li>
            );
          })}
        </ul>
        <ul className="sp-only">
          {spItems.map((item, i) => {
            return (
              <li key={i}>
                <Link to={item.url}>
                  <span>{item.text}</span>
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </section>
  );
}

// [ご担当,&#058;,ご利用ステータス,&#058;]
