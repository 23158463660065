import { useNavigate } from "react-router-dom";
import { getFromLocalStorage } from "../../helpers/storage";

export default function Thanks() {
  const navigate = useNavigate();
  setTimeout(
    () =>
      getFromLocalStorage("token") === null
        ? navigate("/")
        : navigate("/mypage"),
    5000
  );
  return (
    <article className="p-thanks">
      <h2>じぶん薬局へのお問い合わせを受け付けました</h2>
      <div>
        <p className="p-thanks__bold">
          お問い合わせ内容を確認の上、担当者よりご返信致します。
        </p>
        <p>
          お問い合わせ後、5営業日以内に弊社からの返信がない場合、
          お問い合わせが到着していないなどの場合がございます。
          <br />
          その際は、お手数ですが、以下のメールアドレスまで、
          お問い合わせをお願い致します。
        </p>
        <p>「ingo@myclinique.com」</p>
      </div>
    </article>
  );
}
