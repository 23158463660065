import { memo ,useState} from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { getAgeFromValue } from "../../../utils/hooks/getAge";

const MessageList = memo((props) => {

  const [isHideen,setIsHidden]=useState(true)
  const [hiddenCount,setHiddenCount]=useState(0)

  var birthday,
    number,
    message = false,
    date,
    time,
    reserve_id,
    reserve = true,
    patient_name,
    isRed = false;
  if (props.item.hasOwnProperty("reservation_messages")) {
    const { dob, patient_insurance, name } = props.item.patient;
    const {
      reservation_messages,
      id,
      prescription,
      reservation_scheduled_time,
      medication_guidence,
      created_at,
    } = props.item;

    console.log(reservation_messages);
    birthday = dob;

    if (patient_insurance.length != 0)
      number = patient_insurance[0].insurance_number;
    else number = "";

    patient_name = name;

    date = created_at.substring(0, 11);

    time = created_at.substring(11, 16);

    if (reservation_messages.length == 1) {
      if (reservation_messages[0].message == "") message = false;
    }

    date = created_at.substring(0, 10);

    time = created_at.substring(11, 16);

    reserve_id = id;

    const curDate = new Date();
    const month = curDate.getMonth() + 1;
    const toDay = curDate.getFullYear() + "-" + month + "-" + curDate.getDate();
    if (moment(toDay).diff(date, "days") >= 4) isRed = true;
    else isRed = false;
  } else {
    var { date, time, name, birthday, number, red, message, reserve, id } =
      props.item;
    reserve_id = id;
  }

  var count=props.count;

  const getAge=(dob)=>{
    var age=getAgeFromValue(dob);
   
    if(hiddenCount==0){
    if(dob==null || dob==""){
      setHiddenCount(hiddenCount+1)
      setIsHidden(false)
      return ""
    }
  }
    
      
      return age;
    

  }

  console.log(patient_name + "," + message);

  return (
    <li className={isRed ? "c-messageList red" : "c-messageList"}>
      <Link
        to={reserve ? `/reserve/${reserve_id}/` : ``}
        className={reserve ? "label" : ""}
      >
        <p className="c-messageList__time">
          申請日時 &#058;&nbsp; <span>{date}</span>
        </p>
        <div className="c-messageList__title">
          <p className="c-messageList__name">
            患者名 &#058; <span>{patient_name}</span>
            <span class="dob">
              （{birthday} &nbsp; {getAge(birthday)}歳）
            </span>
          </p>
          <p className="c-messageList__number">
            保険証番号 &#058; <span>{number}</span>
          </p>
        </div>
        {message && message != "" ? (
          <p className="c-messageList__message">患者メッセージがあります</p>
        ) : (
          ""
        )}
      </Link>
    </li>
  );
});

export default MessageList;
