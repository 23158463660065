/* eslint-disable no-mixed-operators */
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { patientDetailsRes } from "../../apis/patientDetails";
import { stopMessageObjRes } from "../../apis/stopMessage";
import { MainButton, ActionButton, LinkText } from "../components/projects";
import {
  getDateFromValue,
  getAgeFromValue,
  getJpnDateFromValue,
} from "../../utils/hooks";
import { getFromLocalStorage } from "../../helpers/storage";
import { prefectures } from "../../utils/data/prefectures";
// ダミーデータ
const user = {
  userName: "XXXXXXX",
  birthday: "1975/05/10",
  gender: "女",
  drugHospital: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
  drugConctor: "XXXXXXXXXXXXXXXXXXXXXXXX",
  detail: [
    "お薬の名前",
    "お薬の形（錠剤、カプセル剤など）",
    "お薬の量　1回あたりに飲む量",
    "1日に飲む回数",
    "飲むタイミング",
  ],
  change: true,
  time: 3,
  postNumber: "123-4567",
  address: "東京都 大田区 南久が原 1−2−3",
  phoneNumber: "080-1234-5678",
  email: "XXXXXXX@XXX.com",
  allergy:
    "アレルギー情報。アレルギー情報。アレルギー情報。アレルギー情報。アレルギー情報。アレルギー情報。",
  drugAllergy:
    "アレルギー薬剤情報。アレルギー薬剤情報。アレルギー薬剤情報。アレルギー薬剤情報。",
  insuranceLimit: "2022/12/12",
  insuranceDelivery: "2019/03/31",
  insuranceCode: "12345678 番号 1",
  insuranceGet: "2017/03/31",
  householdName: "山田 太郎",
  insuranceCompany: "株式会社オルトロス",
  insuranceNumber: "12345678",
  issurName: "大和市",
  insurer: "全国健康保険協会 東京支部",
  location: "東京都中野区中野4−10−2",
  disease:
    "基礎疾患内容の記載。基礎疾患内容の記載。基礎疾患内容の記載。基礎疾患内容の記載。基礎疾患内容の記載。",
  hospital: "医療機関名。医療機関名。医療機関名。医療機関名。",
  store: "調剤薬局名。調剤薬局名。調剤薬局名。調剤薬局名。",
  pharmacistName: "薬剤師氏名。薬剤師氏名。薬剤師氏名。薬剤師氏名。",
  status: "連携済み",
  note: "処方にまる悪重要情報の記載。処方にまる悪重要情報の記載。処方にまる悪重要情報の記載。処方にまる悪重要情報の記載。処方にまる悪重要情報の記載。処方にまる悪重要情報の記載。処方にまる悪重要情報の記載。",
};

export default function Patient() {
  const { id } = useParams();

  const [PatientInfo, setPatientInfo] = useState({});
  const [PatientInsuranceInfo, setPatientInsuranceInfo] = useState({});
  const [PatientAddressInfo, setPatientAddressInfo] = useState({});
  const patient = useLocation();

  const navigate = useNavigate();
  const storeId = parseInt(window.localStorage.getItem("drugstoreId"));
  console.log(patient);
  if (patient.state != null) {
    var { patient_id } = patient.state;
  }

  useEffect(() => {
    getFromLocalStorage("token") === null
      ? navigate("/")
      : console.log("works");
    var response1, response2, response3;
    response1 = patientDetailsRes.userInfo(patient_id);
    response1
      .then((res) => {
        console.log(res.data);
        setPatientInfo(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    response2 = patientDetailsRes.userInsuranceInfo(patient_id);
    response2
      .then((res) => {
        setPatientInsuranceInfo(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    response3 = patientDetailsRes.userAddressInfo(patient_id);
    response3
      .then((res) => {
        console.log(res);
        setPatientAddressInfo(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // 編集フォーム
  const [open, setOpen] = useState(true);
  const handleClickEdit = () => {
    setOpen((prevState) => !prevState);
  };

  // 編集フォーム送信
  const onSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const values = Object.fromEntries(data.entries());

    console.log(values);
    var response;
    response = stopMessageObjRes.stopMessage(id, values);
    response.then((res) => {
      setTimeout(() => {
        window.location.href = `/patient/${id}`;
      }, 1000);
    });
  };

  // 遷移

  const handleClick = () => {
    navigate("/patient/file");
  };

  const renderUserInfoAddress = (item) => {
    const codePrefecture = item?.prefecture;
    const resultPrefecture = prefectures.find((d) => d.code === codePrefecture);
    if (!item) return;
    return (
      <div>
        {item?.postal_code && <div>〒{item?.postal_code}</div>}
        <div>
          {(resultPrefecture && resultPrefecture.name) || ""} {item?.city || ""}{" "}
          {item?.address || ""}
        </div>
      </div>
    );
  };

  return (
    <>
      {PatientInfo && PatientAddressInfo && PatientInsuranceInfo && (
        <section className="p-patient__detail">
          <h2 className="c-sectionTitle">
            <span>患者情報詳細</span>
          </h2>

          <dl className="l-table">
            <div>
              <dt>お名前</dt>
              <dd>{PatientInfo.name}</dd>
            </div>
            <div>
              <dt>生年月日</dt>
              {PatientInfo.dob != null || PatientInfo.dob != "" ? (
                <dd>
                  {getDateFromValue(PatientInfo.dob)}(
                  {getAgeFromValue(PatientInfo.dob)}歳)
                </dd>
              ) : (
                ""
              )}
            </div>
            <div>
              <dt>性別</dt>
              <dd>{PatientInfo.gender == "male" ? "性別" : "女性"}</dd>
            </div>
            <div>
              <dt>住所</dt>
              <>
                <dd>
                  {PatientInfo.hasOwnProperty("address") &&
                  renderUserInfoAddress(PatientInfo?.address)}
                </dd>
              </>
            </div>
            <div>
              <dt>電話</dt>
              <dd>
                {PatientInfo.phone_no != null ? PatientInfo.phone_no : ""}
              </dd>
            </div>
            <div>
              <dt>メールアドレス</dt>
              <dd>{PatientInfo.email != null ? PatientInfo.email : ""}</dd>
            </div>
            <div>
              <dt>アレルギー情報</dt>
              <dd>
                {PatientInfo.allergy_info != null
                  ? PatientInfo.allergy_info
                  : ""}
              </dd>
            </div>
            <div>
              <dt>アレルギー反応薬剤</dt>
              <dd>
                {PatientInfo.allergy_reactive != null
                  ? PatientInfo.allergy_reactive
                  : ""}
              </dd>
            </div>
            <div>
              <dt>保険証情報</dt>
              <dd className="insurance">
                <span>
                  有効期限 &#058;{" "}
                  {PatientInfo.hasOwnProperty("patient_insurance") &&
                  PatientInfo.patient_insurance.length != 0
                    ? getJpnDateFromValue(
                        PatientInfo.patient_insurance[0].expiration_date
                      )
                    : ""}
                </span>
                <span>
                  交付年月日 &#058;{" "}
                  {PatientInfo.hasOwnProperty("patient_insurance") &&
                  PatientInfo.patient_insurance.length != 0
                    ? getJpnDateFromValue(
                        PatientInfo.patient_insurance[0].date_of_issuance
                      )
                    : ""}
                  交付
                </span>
                <span>
                  記号 &#058;{" "}
                  {PatientInfo.hasOwnProperty("patient_insurance") &&
                  PatientInfo.patient_insurance.length != 0
                    ? PatientInfo.patient_insurance[0].symbol
                    : ""}
                </span>
                <span>
                  資格取得年月日 &#058;{" "}
                  {getJpnDateFromValue(
                    PatientInfo.hasOwnProperty("patient_insurance") &&
                      PatientInfo.patient_insurance.length != 0
                      ? PatientInfo.patient_insurance[0].date_of_qualification
                      : ""
                  )}
                  交付
                </span>
                <span>
                  世帯主 &#058;{" "}
                  {PatientInfo.hasOwnProperty("patient_insurance") &&
                  PatientInfo.patient_insurance.length != 0
                    ? PatientInfo.patient_insurance[0].name_of_head_of_household
                    : ""}
                </span>
                <span>
                  事業所名称 &#058;{" "}
                  {PatientInfo.hasOwnProperty("patient_insurance") &&
                  PatientInfo.patient_insurance.length != 0
                    ? PatientInfo.patient_insurance[0].office_name
                    : ""}
                </span>
                <span>
                  保険者番号 &#058;{" "}
                  {PatientInfo.hasOwnProperty("patient_insurance") &&
                  PatientInfo.patient_insurance.length != 0
                    ? PatientInfo.patient_insurance[0].insurance_number
                    : ""}
                </span>
                <span>
                  交付者名 &#058;{" "}
                  {PatientInfo.hasOwnProperty("patient_insurance") &&
                  PatientInfo.patient_insurance.length != 0
                    ? PatientInfo.patient_insurance[0].recipient_name
                    : ""}
                </span>
                <span>
                  保険者名称 &#058;{" "}
                  {PatientInfo.hasOwnProperty("patient_insurance") &&
                  PatientInfo.patient_insurance.length != 0
                    ? PatientInfo.patient_insurance[0].insurer_name
                    : ""}
                </span>
                <span>
                  保険者所在地 &#058;{" "}
                  {PatientInfo.hasOwnProperty("patient_insurance") &&
                  PatientInfo.patient_insurance.length != 0
                    ? PatientInfo.patient_insurance[0].insurer_location
                    : ""}
                </span>
              </dd>
            </div>
            <div>
              <dt>過去の処方履歴</dt>
              <dd>
                <Link to={`${window.location.pathname}/history`}>
                  この患者の処方箋履歴をみる
                </Link>
              </dd>
            </div>
            <div>
              <dt>基礎疾患</dt>
              <dd>
                {PatientInfo.hasOwnProperty("patient_insurance") &&
                PatientInfo.patient_insurance.length != 0
                  ? PatientInfo.patient_insurance[0].underlying_disease
                  : ""}
              </dd>
            </div>
            <div>
              <dt>かかりつけ医療機関</dt>
              <dd>
                {PatientInfo.hasOwnProperty("patient_insurance") &&
                PatientInfo.patient_insurance.length != 0
                  ? PatientInfo.patient_insurance[0].family_medical_institution
                  : ""}
              </dd>
            </div>
            <div>
              <dt>かかりつけ調剤薬局</dt>
              <dd>
                {PatientInfo.hasOwnProperty("patient_insurance") &&
                PatientInfo.patient_insurance.length != 0
                  ? PatientInfo.patient_insurance[0].family_dispensing_pharmacy
                  : ""}
              </dd>
            </div>
            <div>
              <dt>担当薬剤師</dt>
              <dd>
                {PatientInfo.hasOwnProperty("patient_insurance") &&
                PatientInfo.patient_insurance.length != 0
                  ? PatientInfo.patient_insurance[0].pharmacist_in_charge
                  : ""}
              </dd>
            </div>
            <div className="last">
              <dt>サービス連携状況</dt>
              <dd>{PatientInfo.is_linked && PatientInfo.pharmacy_visited.length !== 0 && PatientInfo.pharmacy_visited.includes(storeId) ? "連携済み" : "未連携"}</dd>
            </div>
            <div>
              <dt>調重要事項情報</dt>
              <dd>
                {PatientInfo.key_matter_information != null
                  ? PatientInfo.key_matter_information
                  : ""}
              </dd>
            </div>
          </dl>

          <div className={open ? "p-patient__edit open" : "p-patient__edit"}>
            <div onClick={handleClickEdit}>
              編集{open ? "をやめる" : "する"}
            </div>
            <form onSubmit={onSubmit}>
              <textarea
                name="note"
                placeholder="調重要事項情報を入力してください"
                id="stop-message"
                // defaultValue={user.note}
              />
              <MainButton text="この内容で編集する" color="blue" />
            </form>
          </div>

          <ActionButton
            text={`患者情報をCSV\nエクスポート＆インポート`}
            onClick={handleClick}
          />

          <LinkText
            text="処方受け取り予約一覧に戻る"
            url="/reserve"
            className="bold"
          />
        </section>
      )}
    </>
  );
}
