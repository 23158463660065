import axios from "axios";

const stopMessage = async (id, values) => {
  const token = "token" + " " + localStorage.getItem("token");
  const data = {
    key_matter_information: values.note,
  };

  const config = {
    method: "PUT",
    url: `https://dev-api-pharmacy.automagicjapan.tech/api/patient_api/${id}/`,
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  };

  var res = await axios(config);
  return res;
};

export const stopMessageObjRes = {
  stopMessage,
};
