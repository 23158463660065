import axios from "axios";

const prescriptionDetails = async (id) => {
  const token = "token" + " " + localStorage.getItem("token");
  var response;
  response = await axios.get(
    `https://dev-api-pharmacy.automagicjapan.tech/api/patient_prescription/${id}/`,
    {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    }
  );

  return response;
};

const userInfo = async (id) => {
  const token = "token" + " " + localStorage.getItem("token");
  var response;

  response = await axios.get(
    `https://dev-api-pharmacy.automagicjapan.tech/api/patient_api/${id}/`,
    {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    }
  );
  return response;
};

const userInsuranceInfo = async (id) => {
  const token = "token" + " " + localStorage.getItem("token");
  var response;

  response = await axios.get(
    `https://dev-api-pharmacy.automagicjapan.tech/api/patient_insurance_api/${id}/`,
    {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    }
  );
  return response;
};

const userAddressInfo = async (id) => {
  var response;
  const token = "token" + " " + localStorage.getItem("token");
  response = await axios.get(
    `https://dev-api-pharmacy.automagicjapan.tech/api/address_api/${id}/`,
    {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    }
  );
  return response;
};

const patientInfoSearch = async (values) => {
  var res;
  const token = "token" + " " + localStorage.getItem("token");

  // 初来訪のみ',
  // '再訪のみ',
  // '処方受け取り予約未対応のみ',
  // '申請メッセージのある患者のみ',
  // 'リモート服薬指導実施者のみ
  var category;
  if (values.category == "初来訪のみ" || values.category == "First visit only")
    category = 1;
  else if (values.category == "再訪のみ" || values.category == "Revisit only")
    category = 2;
  else if (
    values.category == "処方受け取り予約未対応のみ" ||
    values.category == "Only prescription receipt reservation not supported"
  )
    category = 3;
  else if (
    values.category == "申請メッセージのある患者のみ" ||
    values.category == "Only patients with an application message"
  )
    category = 4;
  else category = 5;

  const data = {};

  var flag = 0;
  if (values.category != "") {
    data.filter_category = category;
    flag = 1;
  }

  if (values.startDate != "" && values.endDate != "") {
    data.start_date = values.startDate;
    data.end_date = values.endDate;
    flag = 1;
  }

  if (values.keyword != "") {
    data.patient = values.keyword;
    flag = 1;
  }

  if (flag == 1) {
    var config = {
      method: "post",
      url: "https://dev-api-pharmacy.automagicjapan.tech/api/patient_info/search/",
      headers: {
        Authorization: token,

        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    res = await axios(config);
    return res;
  }
};

const patientDetails = async (pid) => {
  const token = "token" + " " + localStorage.getItem("token");
  const id = window.localStorage.getItem("drugstoreId");
  var response;
  var config = {
    method: "get",
    url: `https://dev-api-pharmacy.automagicjapan.tech/api/patient_api/?page=${pid}`,
    headers: {
      Authorization: token,

      "Content-Type": "application/json",
    },
  };

  response = await axios(config);

  return response;
};

const patientDetailsId = async (id) => {
  const token = "token" + " " + localStorage.getItem("token");
  var response;
  var config = {
    method: "get",
    url: `https://dev-api-pharmacy.automagicjapan.tech/api/patient_api/?page=${id}`,
    headers: {
      Authorization: token,

      "Content-Type": "application/json",
    },
  };

  response = await axios(config);

  return response;
};

export const patientDetailsRes = {
  prescriptionDetails,
  userInfo,
  userInsuranceInfo,
  userAddressInfo,
  patientInfoSearch,
  patientDetails,
  patientDetailsId,
};
