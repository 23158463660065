import { useState, useEffect } from "react";

import {
  ActionButton,
  PatientList as List,
  MainButton,
  LinkText,
} from "../components/projects";
import { patientDetailsRes } from "../../apis/patientDetails";
import { CsvResObj } from "../../apis/import_export_csv";
import { Navigate, useNavigate } from "react-router-dom";
import { getFromLocalStorage } from "../../helpers/storage";

const items = [
  {
    date: "2021/12/21",
    time: "13:00",
    name: "山田 花子",
    birthday: "1975/10/02",
    number: "12345678",
    red: false,
    id: 1,
  },
  {
    date: "2021/12/21",
    time: "13:00",
    name: "山田 花子",
    birthday: "1975/10/02",
    number: "12345678",
    red: false,
    id: 2,
  },
  {
    date: "2021/12/21",
    time: "13:00",
    name: "山田 花子",
    birthday: "1975/10/02",
    number: "12345678",
    red: false,
    id: 3,
  },
  {
    date: "2021/12/21",
    time: "13:00",
    name: "山田 花子",
    birthday: "1975/10/02",
    number: "12345678",
    red: false,
    id: 4,
  },
  {
    date: "2021/12/21",
    time: "13:00",
    name: "山田 花子",
    birthday: "1975/10/02",
    number: "12345678",
    red: false,
    id: 5,
  },
  {
    date: "2021/12/21",
    time: "13:00",
    name: "山田 花子",
    birthday: "1975/10/02",
    number: "12345678",
    red: false,
    id: 6,
  },
];

export default function PatientFile() {
  const [PatientPrescriptionData, setPatientPrescriptionData] = useState([]);

  const [enable, setEnable] = useState(false);
  const [importError,setImportError]=useState("")
  const navigate = useNavigate();

  useEffect(() => {
    getFromLocalStorage("token") === null
      ? navigate("/")
      : console.log("works");

    var response;

    response = patientDetailsRes.prescriptionDetails();
    response.then((res) => {
      setPatientPrescriptionData(res.data);

      console.log(res.data);
    });
  }, []);

  // インポート処理
  const [importFiles, setImportFiles] = useState(false);
  const handleGetFile = () => {
    setImportFiles(true);
    setOpen(false);
    window.location.href =
      "https://dev-api-pharmacy.automagicjapan.tech/api/patient_info/csv/";
  };

  // 追加表示
  const [open, setOpen] = useState(false);
  const showMoreFiles = () => {
    setOpen(true);
  };

  const importFile = (e) => {
    // const file_select = document.getElementsByName("files")[0].files[0];
    // console.log(file_select);
    const triggerFile = document.getElementById("bannerFile");
    triggerFile.click();

    triggerFile.addEventListener("change", () => {
      const fileSelect = document.getElementById("bannerFile").files[0];
      var response = CsvResObj.importCSV(fileSelect);
      response.then((res) => {
          
        setImportError("");
            setTimeout(()=>{
              window.location.href="/patient"
            },1000)
          
        
      }).catch((err) => {
        setImportError("時間データが形式と一致しません'％Y /％m /％d")
      });
    });
  };

  return (
    <section>
      <h2 className="c-sectionTitle">
        <span>患者情報インポート・エクスポート</span>
      </h2>
      <ActionButton text="CSVファイルをエクスポート" onClick={handleGetFile} />
      <p className="p-patient__attention">
        CSVファイルのエクスポート・インポートで、新規追加はもとより
        <br />
        既存ユーザー情報も上書きが可能です。
        <br />
        情報の追加・変更を行う場合は、まず既存データのCSVをダウンロード
        <br />
        してからご利用ください。
      </p>
      <ActionButton text="CSVファイルをインポート" onClick={importFile} />
      <p id="import-error" style={{marginTop: "5vh",textAlign: "center",color: "red"}}>{importError}</p>

      {importFiles && (
        <article className="p-patient__list margin">
          <h3>{PatientPrescriptionData.length}件の新規追加が完了しました</h3>
          <ul>
            {/* {PatientPrescriptionData && PatientPrescriptionData.map((item, i) => {
              return (
                <List 
                  key={i}
                  item={item}
                  label={false}
                />
              )
            })} */}
          </ul>
          {!open && (
            <MainButton
              text="もっと見る"
              color="message"
              onClick={showMoreFiles}
            />
          )}
          {open && PatientPrescriptionData.length > 1 && (
            <ul>
              {PatientPrescriptionData &&
                PatientPrescriptionData.map((item, i) => {
                  return <List key={i} item={item} label={false} />;
                })}
            </ul>
          )}
           
        </article>
      )}
     
      <LinkText text="患者情報一覧に戻る" url="/patient" className="bold" />
    </section>
  );
}
