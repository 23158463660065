import { memo, useState } from "react";

const FormDateInput = memo((props) => {
  
  const { name, errorMessage, initialValue } = props
  const [ value, setValue ] = useState(initialValue ? initialValue : '')

  const handleChange = (e) => {
    setValue(e.target.value)
  }

  return (
    <>
      <input 
        type="date"
        name={name}
        className="c-input"
        value={value}
        onChange={handleChange}
      />
      {errorMessage && <span className="l-formInput__errorMessage">{errorMessage}</span>}
    </>
  )
})

export default FormDateInput