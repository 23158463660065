import { memo } from "react";
import { Link } from "react-router-dom";

const LinkButton = memo((props) => {
  
  const { text, url } = props

  return (
    <div className="c-linkButton">
      <Link to={url}>
        {text}
      </Link>
    </div>
  )
})

export default LinkButton