import axios from "axios";

const importCSV = async (files) => {
  const token = "token" + " " + localStorage.getItem("token");
  const data = new FormData();
  console.log(files);
  data.append("file", files);
  var res = await axios(
    "https://dev-api-pharmacy.automagicjapan.tech/api/patient_api/upload_data/",
    {
      method: "post",
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
      data: data,
    }
  );

  return res;
};

const exportCSV = async () => {
  var response;
  response = await axios(
    "https://dev-api-pharmacy.automagicjapan.tech/api/patient_info/csv/"
  );
  return response;
};

export const CsvResObj = {
  exportCSV,
  importCSV,
};
