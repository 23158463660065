import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ContextStorage } from "./useContext";
import { Header, Layout, Footer, HeaderNav } from "./views/components/ui";
import {
  SignIn,
  Top,
  ReserveList,
  Reserve,
  HistoryList,
  History,
  PatientList,
  PatientFile,
  Patient,
  SearchMedicine,
  SearchMedicineList,
  Medicine,
  Contact,
  Thanks,
  NewsList,
  News,
  PrivacyPolicy,
} from "./views/pages";
import ScrollToTop from "./utils/hooks/ScrollToTop";
import SignUp from "./views/pages/SignUp";
import Inquiry from "./views/pages/Inquiry";

function App() {
  return (
    <>
      <BrowserRouter>
        <ContextStorage>
          <ScrollToTop>
            <Header />
            <main>
              <HeaderNav />
              <Layout />

              <Routes>
                <Route path="/" element={<SignIn />} />
                <Route path="/mypage" element={<Top />} />

                <Route path="/reserve" element={<ReserveList />} />
                <Route path="/reserve/:id" element={<Reserve />} />

                <Route path="/signup" element={<SignUp />} />

                <Route path="/inquiry" element={<Inquiry />} />

                <Route path="/patient" element={<PatientList />} />
                <Route path="/patient/file" element={<PatientFile />} />
                <Route path="/patient/:id" element={<Patient />} />

                <Route path="/patient/:id/history" element={<HistoryList />} />
                <Route path="/patient/:id/history/:id" element={<History />} />

                <Route path="/medicine" element={<SearchMedicine />} />
                <Route path="/medicine/list" element={<SearchMedicineList />} />
                <Route path="/medicine/:id" element={<Medicine />} />

                <Route path="/contact" element={<Contact />} />
                <Route path="/thanks" element={<Thanks />} />

                <Route path="/news" element={<NewsList />} />
                <Route path="/news/:id" element={<News />} />

                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              </Routes>
            </main>
            <Footer />
          </ScrollToTop>
        </ContextStorage>
      </BrowserRouter>
    </>
  );
}

export default App;
