import { memo,useEffect,useState } from "react";
import { Link } from "react-router-dom";
import { noticeResObj } from "../../../apis/noticeAPI";
import moment from "moment";
const NewsList = memo((props) => {

  const { date, title, id, image_registration } = props.item;




  const [renderNotice,setRenderNotice]=useState([]);
  const [noticeDate,setDate]=useState("");
  const [noticeTime,setTime]=useState("");
  const [startDate,setStartDate]=useState("");
  const [endDate,setEndDate]=useState("")
  

  const curDate=new Date()
  const month=curDate.getMonth()+1
  const today=curDate.getFullYear()+"-"+month+"-"+curDate.getDate()





  useEffect(() => {
    var response;
 
    response = noticeResObj.noticeApiId(id);

    response.then((res) => {

      console.log(res);

     var tempDate=res.data.created_at.substring(0,10);
     var tempTime=res.data.created_at.substring(11,16);
     setDate(tempDate)
     setTime(tempTime);
      setRenderNotice(res.data);
      const start_date=res.data.deliver_start_time
      const end_date=res.data.deliver_end_time

      setStartDate(start_date);
      setEndDate(end_date);

    })
  }, [])

 
   
 


  return (
    <>
    {renderNotice && (moment(today).isSameOrAfter(startDate) && moment(today).isSameOrBefore(endDate)) &&
    <li className="c-newsList">
      <Link to={`/news/${id}`} >
        <p className="c-newsList__date">
          {noticeDate}
          <span>{noticeTime}</span>
        </p>
        <p className="c-newsList__title">
          {renderNotice.title}
        </p>
      </Link>
    </li>
}
    </>
  )
})

export default NewsList