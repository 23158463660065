import axios from "axios";

const signUpAPI = (data) =>
  axios.post("https://dev-api-pharmacy.automagicjapan.tech/api/pharmacy/signup/", data);

const signUp = {
  signUpAPI,
};

export default signUp;
