import { MessageList, NewsList, LinkButton } from "../components/projects";
import { useContext, useEffect, useState } from "react";
import { noticeResObj } from "../../apis/noticeAPI";
import { reservationResObj } from "../../apis/reservation";
import { useContextData } from "../../useContext";
import { useNavigate } from "react-router-dom";
import { getFromLocalStorage } from "../../helpers/storage";
import { Pagination } from "antd";

export default function Top() {
  const [
    storeData,
    setStoreData,
    setDrugSearchData,
    drugSearchData,
    updateNav,
    setUpdateNav,
  ] = useContext(useContextData);

  // ダミーデータ
  const reserveItems = [
    {
      date: "2021/12/21",
      time: "13:00",
      name: "山田 花子",
      birthday: "1975/10/02",
      number: "12345678",
      red: true,
      message: true,
      reserve: true,
      id: 1,
    },
    {
      date: "2021/12/21",
      time: "13:00",
      name: "山田 花子",
      birthday: "1975/10/02",
      number: "12345678",
      red: false,
      message: false,
      reserve: true,
      id: 2,
    },
    {
      date: "2021/12/21",
      time: "13:00",
      name: "山田 花子",
      birthday: "1975/10/02",
      number: "12345678",
      red: false,
      message: false,
      reserve: true,
      id: 3,
    },
    {
      date: "2021/12/21",
      time: "13:00",
      name: "山田 花子",
      birthday: "1975/10/02",
      number: "12345678",
      red: false,
      message: false,
      reserve: true,
      id: 4,
    },
    {
      date: "2021/12/21",
      time: "13:00",
      name: "山田 花子",
      birthday: "1975/10/02",
      number: "12345678",
      red: false,
      message: false,
      reserve: true,
      id: 5,
    },
    {
      date: "2021/12/21",
      time: "13:00",
      name: "山田 花子",
      birthday: "1975/10/02",
      number: "12345678",
      red: true,
      message: true,
      reserve: true,
      id: 6,
    },
  ];

  const remortItems = [
    {
      date: "2021/12/21",
      time: "13:00",
      name: "山田 花子",
      birthday: "1975/10/02",
      number: "12345678",
      red: true,
      message: true,
      reserve: true,
      id: 1,
    },
    {
      date: "2021/12/21",
      time: "13:00",
      name: "山田 花子",
      birthday: "1975/10/02",
      number: "12345678",
      red: false,
      message: false,
      reserve: false,
      id: 2,
    },
    {
      date: "2021/12/21",
      time: "13:00",
      name: "山田 花子",
      birthday: "1975/10/02",
      number: "12345678",
      red: false,
      message: false,
      reserve: false,
      id: 3,
    },
    {
      date: "2021/12/21",
      time: "13:00",
      name: "山田 花子",
      birthday: "1975/10/02",
      number: "12345678",
      red: false,
      message: false,
      reserve: false,
      id: 4,
    },
  ];

  const newsItems = [
    {
      date: "2021/12/21",
      time: "13:22",
      title:
        "お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。",
      id: 1,
    },
    {
      date: "2021/12/21",
      time: "13:22",
      title:
        "お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。",
      id: 2,
    },
  ];

  const [noticeData, setNoticeData] = useState([]);
  const [reservationReceipt, setReservationReceipt] = useState([]);
  const [remoteMedication, setRemoteMedication] = useState([]);
  const [enable, setEnable] = useState(false);

  const [reservePage, setReservePage] = useState({ reserve: 1 });
  const [reserveCount, setReserveCount] = useState();

  const onReserve = (page) => {
    setReservePage({ reserve: page });
  };

  const navigate = useNavigate();

  useEffect(() => {
    getFromLocalStorage("token") === null ? navigate("/") : navigate("/mypage");
    // getFromLocalStorage("token") === null ? navigate("/") : setEnable(true);

    updateNav.nav();

    var response,
      response1,
      tempArr1 = [],
      tempArr2 = [];
    const noticeApiArr = [];
    response = noticeResObj.noticeapi();

    response
      .then((res) => {
        console.log(res);

        setNoticeData(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });

    response1 = reservationResObj.reservationDetails(reservePage.reserve);
    response1.then((res) => {
      console.log(res);
      setReserveCount({
        count: res.data.count,
        next: res.data.next,
        previous: res.data.previous,
      });
      for (var i = 0; i < res.data.results.length; i++) {
        tempArr1.push(res.data.results[i]);
      }

      setReservationReceipt(tempArr1);

      for (var j = 0; j < res.data.results.length; j++) {
        if (res.data.results[j].medication_guidence)
          tempArr2.push(res.data.results[j]);
      }

      setRemoteMedication(tempArr2);
    });
  }, [reservePage]);
  return (
    <>
      <section className="p-home__message">
        <h2 className="c-sectionTitle">
          <span>新着処方受け取り予約</span>
        </h2>
        <ul className="p-home__message__list">
          {reservationReceipt.map((item, i) => {
            return <MessageList key={i} item={item} count={i} />;
          })}
          <div style={{ marginLeft: "48%" }}>
            <Pagination
              // defaultCurrent={1}
              total={reserveCount && reserveCount.count && reserveCount?.count}
              onChange={onReserve}
              //current={pageNumber}
              pageSize={10}
              showSizeChanger={false}
              size="small"
            />
          </div>
        </ul>
      </section>
      <section className="p-home__message">
        <h2 className="c-sectionTitle">
          <span>リモート服薬指導申請</span>
        </h2>
        <ul className="p-home__message__list">
          {remoteMedication.map((item, i) => {
            return <MessageList key={i} item={item} count={i} />;
          })}
          <div style={{ marginLeft: "48%" }}>
            <Pagination
              // defaultCurrent={1}
              total={remoteMedication.length}
              // onChange={onReserve}
              //current={pageNumber}
              pageSize={20}
              showSizeChanger={false}
              size="small"
            />
          </div>
        </ul>
      </section>
      <section className="p-home__news">
        <h2 className="c-sectionTitle">
          <span>じぶん薬局からのお知らせ</span>
        </h2>
        <ul>
          {noticeData?.map((item, i) => {
            return <NewsList key={i} item={item} />;
          })}
        </ul>
      </section>
      <LinkButton url="/contact" text="じぶん薬局へのお問い合わせ" />
    </>
  );
}
//new comment
