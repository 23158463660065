import { memo, useState } from "react";
import { useParams } from "react-router-dom";
import { reservationResObj } from "../../../apis/reservation";
const FormRadio = memo((props) => {

  const { title, name, options, required, method, initial } = props;
  const {id}=useParams();


  console.log("formRadio",name);

 
 

  const [ value, setValue ] = useState(options[initial].value)
  const handleChange = (e) => {

    const reservaVal=document.getElementsByName("reserveValue");
    const teachVal=document.getElementsByName("teachValue");

    if(reservaVal[0].checked )
     props.check()

    if(teachVal.length!=0)
    {
      if(teachVal[0].checked)
      props.check()
    }

    if(method) {
      method(e.target.value)
      setValue(e.target.value)
    } else {
      setValue(e.target.value)
    }
    const select_val=e.target.value;
    console.log(select_val);

   
  // if(select_val=="承諾する" && title=="予約申請日について")
  // {
  //   var response;
  //   response=reservationResObj.reservationSelect(true,"",undefined,id);
  //   response.then((res)=>{
  //     console.log(res);
  //   })
  // }
  // else if(select_val=="承諾する" && title=="リモート服薬指導予約申請日について"){
  //   var response;
  //   response=reservationResObj.medicationSelect(true,"",undefined,id);
  //   response.then((res)=>{
  //     console.log(res);
  //   })
  // }
    
   
  }

  return (
    <div className="l-formInput__radio">
      <p>
        {title}
        {required && <span className="l-formInput__required">必須</span>}
      </p>
      {options.map((option, i) => {
        return(
          <label key={i}>
            <input 
              type="radio"
              name={name}
              value={option.value}
              onChange={handleChange}
              checked={value === option.value ? "checked" : ""}
             
            />
            <span className="l-formInput__radio__text">{option.label}</span>
          </label>
        )
      })}
    </div>
  )
})

export default FormRadio