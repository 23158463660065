import { memo } from "react";

const ActionButton = memo((props) => {

  const { text, onClick } = props


  const path=window.location.pathname;

  


  return (
    <>
    {text=="CSVファイルをエクスポート" ?
    <>
    <div className="c-actionButton">
     <button onClick={onClick} >{text}</button>
      </div>
     
      </>
      
      : <> <div className="c-actionButton">
        
      <button onClick={onClick} >{text}</button>
    </div>
    
    {path=="/patient/file" ?
     <center><input type="file" name="files" id="bannerFile" style={{marginTop: "5vh"}} hidden/></center>
    : ""}
     </>
    
    }
 
    </>
  )
})

export default ActionButton