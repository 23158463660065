// 日付変換（2021/01/01 → 2021年1月1日）
export const getDateFromValue = (value) => {

  console.log(value);
  if(value==undefined || value=="" || value==null || value=="NULL")
 
  return "";

 
  const d = new Date(value)
  const year = d.getFullYear()
  const month = d.getMonth() + 1
  const date = d.getDate()

  return year + '年' + month + '月' + date + '日'
}

// 日付変換（2021/01/01 → 令和3年1月1日）
export const getJpnDateFromValue = (value) => {

  if(value==undefined || value=="" || value==null || value=="NULL")
  return "";
  
  const d = new Date(value)
  const options = {era: 'long', year:'numeric', month:'long', day:'numeric'};
  
  return new Intl.DateTimeFormat('ja-JP-u-ca-japanese', options).format(d)
  
}