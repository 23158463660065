import { memo } from "react";
import { Link } from "react-router-dom";

const LinkText = memo((props) => {
  const { url, text, className ,patientId} = props

  return (
    <div className={`c-linkText ${className ? className : ""}`}>
      <Link to={url} state={{patient_id: patientId}} >{text}</Link>
    </div>
  )
})

export default LinkText