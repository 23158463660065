// 処理を伴うボタン
import { memo } from "react"

const MainButton= memo((props) => {

  const { text, onClick, color } = props



  return (
    <button className={`c-mainButton ${color}`} onClick={onClick} >
      {text}
    </button>
  )
})

export default MainButton