// 年齢計算
export const getAgeFromValue = (value) => {

  console.log(value);

  if(value==null || value=="" || value==undefined || value=="NULL")
    return "";
    else{

  
  const today = new Date()
  const d = new Date(value)
  const thisYearsBirthday = `${today.getFullYear()}/${d.getMonth() + 1}/${d.getDate()}`

  let age = today.getFullYear() - d.getFullYear()
  if(today <  new Date(thisYearsBirthday)) {
    age--
  }


  return age
}
}