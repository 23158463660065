import axios from "axios";

const getHistory = async (id) => {
  const token = "token" + " " + localStorage.getItem("token");
  var response;
  response = await axios.get(
    `https://dev-api-pharmacy.automagicjapan.tech/api/prescription_api/${id}`,
    {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    }
  );

  return response;
};

export const historyResObj = {
  getHistory,
};
