import { useState, memo, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FormInput, MainButton, LinkText } from "../components/projects";
import { isEmail, isPassword } from "../../utils/validation";
import logInAPI from "../../apis/login";
import { getFromLocalStorage, setLocalStorage } from "../../helpers/storage";
import { noticeResObj } from "../../apis/noticeAPI";
import { NewsList } from "../components/projects";
import SignUpLink from "../components/projects/SignUpLink";
import { useContextData } from "../../useContext";

const SignIn = memo(() => {
  const [noticeData, setNoticeData] = useState([]);

  const [userInfo, setUserInfo] = useContext(useContextData);
  const loginCheck = (response) => {
    console.log(response);
    window.localStorage.setItem("name", response.data.user_data.email);
    window.localStorage.setItem(
      "drugstore",
      response.data.drugstore.drugstore_name
    );
    window.localStorage.setItem("drugstoreId",response.data.drugstore.id)
  };

  // 入力バリデーション
  const [errorMessages, setErrorMessages] = useState({});
  const isValidate = (values) => {
    let messages = {};

    messages.email = isEmail(values.email);
    messages.password = isPassword(values.password, "ログインパスワード");

    setErrorMessages({ ...messages });
    return Object.values(messages).every((message) => message === "");
  };

  // ログイン処理
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const values = Object.fromEntries(data.entries());
    document.getElementById("error-message").innerHTML = "";

    if (isValidate(values)) {
      try {
        const response = await logInAPI.login(values);

        const id = response.data.id;
        console.log("response", response);
        setLocalStorage({ token: response.data.user_data.user_token });
        response.status == 200 ? setSwtch(true) : setSwtch(false);
        response.status == 200 ? loginCheck(response) : navigate("/");

        setTimeout(() => {
          window.location.href = "/mypage";
        }, 1000);
      } catch (e) {
        document.getElementById("error-message").innerHTML =
          e.response.data.hasOwnProperty("password")
            ? e.response.data.password
            : e.response.data;
      }
      const authentification = getFromLocalStorage("token");
    }

    // if (isValidate(values)) {
    //   try {
    //     const response = await logInAPI.login(values);
    //     setLocalStorage({ token: response.data.user_token });
    //     console.log(response);
    //     response.status == 200 ? setSwtch(true) : setSwtch(false);
    //     response.status == 200 ? loginCheck() : navigate("/");
    //     toast()
    //   } catch (e) {
    //     console.log(e);
    //     // document.getElementById("error-message").innerHTML=(e.response.data.hasOwnProperty("password"))? e.response.data.password: e.response.data;
    //     toast("IDとパスワードが一致しません。再度お試しください");
    //     // toast("Please check the contents and enter again.");
    //     console.log("error", e);
    //   }

    //   console.log("ok");
    // }
  };
  var path = window.location.pathname;

  const [swtch, setSwtch] = useState(false);

  useEffect(() => {
    getFromLocalStorage("token") == null ? navigate("/") : navigate("mypage");
    var response;
    response = noticeResObj.noticeapi();
    response.then((res) => {
      const temp_arr = res.data.results.filter(
        (item) =>
          item.delivery_target.delivery_target_choice == 2 ||
          item.delivery_target.delivery_target_choice == 4
      );

      setNoticeData(temp_arr);
    });
  }, []);

  return (
    <>
      <section className="p-signIn">
        {errorMessages.email || errorMessages.password ? (
          <small className="p-signIn__error">
            ご入力いただいたログインID・パスワードに誤りがあります。
            <br />
            内容をご確認の上、再度ご入力ください。
          </small>
        ) : (
          ""
        )}
        <h2>薬局・薬店様管理画面ログイン</h2>
        <form className="l-formInput" onSubmit={handleSubmit}>
          <FormInput
            name="email"
            label="ログインID（メールアドレス）"
            placeholder="ログインID（メールアドレス）を入力してください"
            required="true"
            type="email"
            errorMessage={errorMessages.email ? errorMessages.email : ""}
            autocomplete="email"
          />
          <FormInput
            name="password"
            label="ログインパスワード"
            placeholder="ログインパスワードを入力してください"
            required="true"
            type="password"
            errorMessage={errorMessages.password ? errorMessages.password : ""}
            autocomplete="off"
          />
          <p id="error-message" style={{ color: "red" }}></p>

          <MainButton text="ログインする" color="blue" />
        </form>

        {/* <LinkText url="/password" text="ログインID・パスワードを忘れた方へ" /> */}
      </section>
      <br />
      <br />
      <SignUpLink />

      <ul>
        {noticeData &&
          noticeData.map((item, i) => {
            return <NewsList key={i} item={item} />;
          })}
      </ul>
    </>
  );
});

export default SignIn;
