import { NewsList as List, LinkButton } from "../components/projects";
import { useState,useEffect } from 'react';
import { noticeResObj } from "../../apis/noticeAPI";

// お知らせダミーデータ
const newsItems = [
  {
    date: '2021/12/21',
    time: '13:22',
    title: 'お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。',
    id: 1
  },
  {
    date: '2021/12/21',
    time: '13:22',
    title: 'お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。',
    id: 2
  },
  {
    date: '2021/12/21',
    time: '13:22',
    title: 'お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。',
    id: 3
  },
  {
    date: '2021/12/21',
    time: '13:22',
    title: 'お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。',
    id: 4
  },
  {
    date: '2021/12/21',
    time: '13:22',
    title: 'お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。',
    id: 5
  },
]

export default function NewsList() {

  const [notice,setNotice]=useState([]);

  useEffect(()=>{

    var response;
   
   response= noticeResObj.noticeapi();
   response.then((res)=>{
    setNotice(res.data.results);
   })

  },[])
  return (

    <>
    
      <section className="p-home__news">
        <h2 className="c-sectionTitle">
          <span>じぶん薬局からのお知らせ</span>
        </h2>
        <ul>
          {notice && notice.map((item, i) => {
            return (
              <List
                key={i}
                item={item}
              />
            )
          })}
        </ul>
      </section>
      <LinkButton 
        url='/contact'
        text='じぶん薬局へのお問い合わせ'
      />
      </>
  )
}