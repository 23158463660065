import axios from "axios";

const inquiry = (data) => {
  const token = "token" + " " + localStorage.getItem("token");
  if (token == null || token == undefined)
    axios.post("https://dev-api-pharmacy.automagicjapan.tech/api/inquiry_api/", data);
  else {
    const config = {
      method: "post",
      url: "https://dev-api-pharmacy.automagicjapan.tech/api/inquiry_api/",
      headers: {
        Authorization: token,
      },
      data: data,
    };
    axios(config);
  }
};

const inquiryAPI = {
  inquiry,
};

export default inquiryAPI;
