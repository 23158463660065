import axios from "axios";




const getImage=async ()=>{
    const token = "token" + " " + localStorage.getItem("token");


    const config={
        method: "get",
        url: `https://dev-api-pharmacy.automagicjapan.tech/api/pharma_manf/`,
        // headers: {
        //     "Authorization": token,
           
        //   }
    }

  var res=await axios(config)

  return res;

}


export const imgResObj={
    getImage
}