import { ReservationList, SearchPatientForm } from "../components/projects";
import axios from "axios";
import { useEffect, useState } from "react";
import { reservationResObj } from "../../apis/reservation";
import { getFromLocalStorage } from "../../helpers/storage";
import { Navigate, useNavigate } from "react-router-dom";
import { Pagination } from "antd";
// ダミーデータ
const reserveItems = [
  {
    date: "2021/12/21",
    time: "13:00",
    name: "山田 花子",
    birthday: "1975/10/02",
    number: "12345678",
    hospital: "医療機関名",
    doctor: "担当医師名",
    medicineName:
      "おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。",
    red: true,
    message: true,
    id: 1,
  },
  {
    date: "2021/12/21",
    time: "13:00",
    name: "山田 花子",
    birthday: "1975/10/02",
    number: "12345678",
    hospital: "医療機関名",
    doctor: "担当医師名",
    medicineName:
      "おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。",
    red: false,
    remort: true,
    id: 2,
  },
  {
    date: "2021/12/21",
    time: "13:00",
    name: "山田 花子",
    birthday: "1975/10/02",
    number: "12345678",
    hospital: "医療機関名",
    doctor: "担当医師名",
    medicineName:
      "おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。",
    red: false,
    id: 3,
  },
  {
    date: "2021/12/21",
    time: "13:00",
    name: "山田 花子",
    birthday: "1975/10/02",
    number: "12345678",
    hospital: "医療機関名",
    doctor: "担当医師名",
    medicineName:
      "おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。",
    red: false,
    id: 4,
  },
  {
    date: "2021/12/21",
    time: "13:00",
    name: "山田 花子",
    birthday: "1975/10/02",
    number: "12345678",
    hospital: "医療機関名",
    doctor: "担当医師名",
    medicineName:
      "おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。",
    red: true,
    message: true,
    id: 5,
  },
  {
    date: "2021/12/21",
    time: "13:00",
    name: "山田 花子",
    birthday: "1975/10/02",
    number: "12345678",
    hospital: "医療機関名",
    doctor: "担当医師名",
    medicineName:
      "おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。",
    red: false,
    remort: true,
    id: 6,
  },
  {
    date: "2021/12/21",
    time: "13:00",
    name: "山田 花子",
    birthday: "1975/10/02",
    number: "12345678",
    hospital: "医療機関名",
    doctor: "担当医師名",
    medicineName:
      "おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。",
    red: false,
    remort: true,
    id: 7,
  },
  {
    date: "2021/12/21",
    time: "13:00",
    name: "山田 花子",
    birthday: "1975/10/02",
    number: "12345678",
    hospital: "医療機関名",
    doctor: "担当医師名",
    medicineName:
      "おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。",
    red: false,
    id: 8,
  },
  {
    date: "2021/12/21",
    time: "13:00",
    name: "山田 花子",
    birthday: "1975/10/02",
    number: "12345678",
    hospital: "医療機関名",
    doctor: "担当医師名",
    medicineName:
      "おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。",
    red: false,
    id: 9,
  },
  {
    date: "2021/12/21",
    time: "13:00",
    name: "山田 花子",
    birthday: "1975/10/02",
    number: "12345678",
    hospital: "医療機関名",
    doctor: "担当医師名",
    medicineName:
      "おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。おくすりの名前。",
    red: false,
    id: 10,
  },
];

export default function ReserveList() {
  // 表示件数セレクトボックス
  const display = ["10", "50", "100", "200"];

  const [reservePres, setReservePres] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [isSearch, setIsSearch] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const navigate = useNavigate();

  const reservationData = [];

  useEffect(() => {
    // getFromLocalStorage("token") === null
    //   ? navigate("/")
    //   : navigate("/reserve");

    if (getFromLocalStorage("token") === null) {
      console.log("token not  present");
      navigate("/");
    } else {
      console.log("token present");
    }

    var response;
    response = reservationResObj.reservationDetails(pageNumber);

    response.then((res) => {
      setReservePres(res.data.results);
      setTotalCount(res.data.count);
      setIsSearch(false);

      console.log(res);
    });
    setIsLoading((isLoading) => !isLoading);
  }, [pageNumber]);

  const onChange = (page) => {
    setPageNumber(page);
  };

  const getPatientDetails = (values) => {
    console.log(values);

    var response;

    response = reservationResObj.reserveInfoSearch(values);
    response.then((res) => {
      setTotalCount(res.data.length);
      if (res.data.length !== 0) setReservePres(res.data);
      else setReservePres([]);
    });
  };

  const checkLoading = () => {
    if (isLoading) {
      <p style={{ textAlign: "center", fontSize: "1.5rem", color: "red" }}>
        データが見つかりませんでした
      </p>;
    } else return "";
  };

  const showResults = (e) => {
    var len;

    console.log(totalCount);

    if (totalCount <= e.target.value) {
      if (totalCount % 10 != 0) len = totalCount / 10 + 1;
      else len = parseInt(totalCount / 10);
    } else if (e.target.value <= totalCount) {
      if (e.target.value % 10 != 0) len = e.target.value / 10 + 1;
      else len = parseInt(e.target.value / 10);
    }
    console.log(len);
    var tempArr = [];
    var count = 0;

    if (!isSearch) {
      for (var i = 1; i <= len; i++) {
        var response = reservationResObj.reservationDetails(i);
        response.then((res) => {
          for (var j = 0; j < res.data.results.length; j++) {
            tempArr.push(res.data.results[j]);
          }
          count += res.data.results.length;
          console.log(count);

          if (
            count == totalCount ||
            (count < totalCount && e.target.value == count)
          ) {
            setTimeout(() => {
              console.log(tempArr);
              setReservePres(tempArr);
            }, count * 60);
          }
        });
      }
    } else {
      var lens;
      if (e.target.value < totalCount) lens = e.target.value;
      else if (totalCount < e.target.value) lens = totalCount;

      var tempArr1 = reservePres;
      var tempArr2 = [];
      for (var j = 0; j < lens; j++) {
        tempArr2.push(tempArr1[j]);
      }

      setReservePres(tempArr2);
    }
  };

  return (
    <section className="p-reserve__list">
      <h2 className="c-sectionTitle">
        <span>処方受け取り予約</span>
      </h2>
      <SearchPatientForm url="/reserve" patient={getPatientDetails} />
      <div className="c-display">
        <p>一覧表示件数</p>
        <div className="c-selectBox__container">
          <select onChange={showResults}>
            {display.map((item, i) => {
              return (
                <option value={item} key={i}>
                  {item}件
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <ul>
        <>
          {reservePres.length !== 0 ? (
            <>
              {reservePres &&
                reservePres.map((item, i) => {
                  return <ReservationList key={i} item={item} />;
                })}
            </>
          ) : (
            checkLoading()
          )}
        </>
      </ul>
      <div style={{ width: 'fit-content', display: 'flex', margin: '0 auto' }}>
        <Pagination
          // defaultCurrent={1}
          total={totalCount}
          onChange={onChange}
          //current={pageNumber}
          pageSize={10}
          showSizeChanger={false}
        />
      </div>
    </section>
  );
}
