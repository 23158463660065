import axios from "axios";
import { getFromLocalStorage } from "../helpers/storage";

const token = "token" + " " + getFromLocalStorage("token");

console.log("outside the function", token);

const reservation = async () => {
  const token = "token" + " " + localStorage.getItem("token");
  var res;
  res = await axios.get(
    "https://dev-api-pharmacy.automagicjapan.tech/api/prescription_api/",
    {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    }
  );
  return res;
};

const reservationReceipt = async () => {
  var res;
  const token = "token" + " " + localStorage.getItem("token");
  res = await axios.get(
    "https://dev-api-pharmacy.automagicjapan.tech/api/prescription_receipt/",
    {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    }
  );
  return res;
};

const reservationDetails = async (id) => {
  var res;
  const token = "token" + " " + getFromLocalStorage("token");
  console.log("token in the function", token);
  res = await axios.get(`https://dev-api-pharmacy.automagicjapan.tech/api/for_reservation/?page=${id}`, {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  });
  return res;
};

const reservationDetailsWithId = async (id) => {
  var res;
  const token = "token" + " " + localStorage.getItem("token");

  res = await axios.get(
    `https://dev-api-pharmacy.automagicjapan.tech/api/for_reservation/${id}`,
    {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    }
  );
  return res;
};

const reservationSelect = async (logic, time, date, id) => {
  const token = "token" + " " + localStorage.getItem("token");
  if (date != undefined) {
    if (date.length != 0 && time.length != 0) {
      const getDate = new Date();
      const newDate = date + "T" + time + ":" + getDate.getSeconds() + ".00Z";
      date = newDate;
    }
  }
  var res;
  const data = JSON.stringify({
    is_accepted: logic,

    reservation_scheduled_time: date,
  });

  const config = {
    method: "put",
    url: `https://dev-api-pharmacy.automagicjapan.tech/api/for_reservation/${id}/`,

    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  res = await axios(config);
  return res;
};

const medicationSelect = async (logic, time, date, id) => {
  const token = "token" + " " + localStorage.getItem("token");

  if (date != undefined) {
    if (date.length != 0 && time.length != 0) {
      const getDate = new Date();
      const newDate = date + "T" + time + ":" + getDate.getSeconds() + ".00Z";
      date = newDate;
    }
  }
  var res;
  const data = JSON.stringify({
    medication_guidence: logic,
    guidance_scheduled_time: date,
  });

  const config = {
    method: "put",
    url: `https://dev-api-pharmacy.automagicjapan.tech/api/for_reservation/${id}/`,

    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  res = await axios(config);
  return res;
};
const reserveInfoSearch = async (values) => {
  var res;
  const token = "token" + " " + localStorage.getItem("token");
  // 初来訪のみ',
  // '再訪のみ',
  // '処方受け取り予約未対応のみ',
  // '申請メッセージのある患者のみ',
  // 'リモート服薬指導実施者のみ
  var category;
  if (values.category == "初来訪のみ" || values.category == "First visit only")
    category = 1;
  else if (values.category == "再訪のみ" || values.category == "Revisit only")
    category = 2;
  else if (
    values.category == "処方受け取り予約未対応のみ" ||
    values.category == "Only prescription receipt reservation not supported"
  )
    category = 3;
  else if (
    values.category == "申請メッセージのある患者のみ" ||
    values.category == "Only patients with an application message"
  )
    category = 4;
  else category = 5;

var data={}
var flag=0
  if(values.category!=""){  
    data.filter_category=category;
    flag=1;
  }

  if(values.startDate!="" && values.endDate!=""){
  data.start_date=values.startDate
  data.end_date=values.endDate
  flag=1;
  }

  if(values.keyword!=""){
  data.patient=values.keyword;
  flag=1;
  }




 if(flag==1){
  var config = {
    method: "post",
    url: "https://dev-api-pharmacy.automagicjapan.tech/api/prescription_reservation/search/",
    headers: {
      Authorization: token,

      "Content-Type": "application/json",
    },
    data: data,
  };

  res = await axios(config);
  return res;
}
}

export const reservationResObj = {
  reservation,
  reservationReceipt,
  reservationDetails,
  reservationDetailsWithId,
  reservationSelect,
  medicationSelect,
  reserveInfoSearch,
};
