import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getFromLocalStorage } from "../../helpers/storage";
import { MedicineInputForm, ActionButton } from "../components/projects";

export default function SearchMedicine() {
  const navigate = useNavigate();

  useEffect(() => {
    getFromLocalStorage("token") === null ? navigate("/") : console.log("work");
  }, []);

  return (
    <section className="p-searchMedicine__input">
      <h2 className="c-sectionTitle">
        <span>OTC医薬品・医療用医薬品検索</span>
      </h2>
      <MedicineInputForm />
    </section>
  );
}
