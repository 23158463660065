import { Link,useParams } from "react-router-dom"
import { LinkButton } from "../components/projects";
import { useEffect,useState } from 'react';
import { noticeResObj } from "../../apis/noticeAPI";
// ダミーデータ
const item = {
  date: '2021/12/21',
  time: '13:22',
  title: 'お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。',
  img: '/assets/img/news-dammy.jpg',
  text: 'お知らせ詳細テキスト。お知らせ詳細テキスト。お知らせ詳細テキスト。お知らせ詳細テキスト。お知らせ詳細テキスト。お知らせ詳細テキスト。お知らせ詳細テキスト。お知らせ詳細テキスト。お知らせ詳細テキスト。お知らせ詳細テキスト。お知らせ詳細テキスト。お知らせ詳細テキスト。お知らせ詳細テキスト。お知らせ詳細テキスト。'
}

export default function News() {

 const {id}=useParams()


  
  const [noticeContent,setNoticeContent]=useState([])
  const [date,setDate]=useState("");
  const [time,setTime]=useState("");


  useEffect(()=>{

     var response=noticeResObj.noticeApiId(id);
     response.then((res)=>{
       const Date=res.data.created_at.substring(0,10);
       const Time=res.data.created_at.substring(11,16);
       setDate(Date);
       setTime(Time);
      setNoticeContent(res.data);
     })
  },[])




  return (
    <>
    {noticeContent && <>
  
       <article className="p-news__article">
      
        <p className="p-news__date">
          
         
          <span>{date}</span>
          <span>{time}</span>
          
          
           
           
        </p>
        <h2>
          {noticeContent.title}
        </h2>
        <div>
          <div className="p-news__img">
            <img src={noticeContent.image_registration} alt={noticeContent.title} />
          </div>
          <p className="p-news__text">
            {noticeContent.text}
          </p>
          <p className="p-news__text">
            {noticeContent.text}
          </p>
          <p className="p-news__text">
            {noticeContent.text}
          </p>
          <p className="p-news__text">
            {noticeContent.text}
          </p>
          <div className="c-linkText p-news__link">
            <Link to='/news'>お知らせ一覧に戻る</Link>
          </div>
        </div>
          
      </article>

      <LinkButton
        text="じぶん薬局へのお問い合わせ"
        url="/contact"
        color="blue"
      />

      </>
}
        

    </>
  )
}